import parse from 'html-react-parser'
import { IoMdClose } from 'react-icons/io'
import styles from './styles/modalcontent.module.scss'

const ModalContent = ({ modalDirector, setShowModal, closeModal }) => {

    return (
        <div className={styles.container}>
            <span onClick={closeModal} className={styles.close}><IoMdClose /></span>
            <picture>
                <img src={modalDirector.photo} alt={modalDirector.name} />
            </picture>
            <section>
                <h3>{modalDirector.name}</h3>
                <h4>{modalDirector.role}</h4>
                <p>{parse(modalDirector.biography)}</p>
            </section>
        </div>
    )
}

export default ModalContent
