import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);


export const navbarGsap = (container) => {
    const tl = gsap.timeline()
    tl.from([container.current], {
        xPercent: -100,
        opacity: 0,
        duration: 2,
        ease: 'circ'
    })
}