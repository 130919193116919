import TextInput from './TextInput'
import SelectInput from './SelectInput'
import styles from '../styles/inputscontainer.module.scss'
import UploadInput from './UploadInput'
import DatePickerInput from './DatePicker'

const BasicInformationForm = ({ formik, setDisplayPicture }) => {
    return (
        <>
            <h3>Personal</h3>
            <div className={styles.container}>
                <div className={styles.double}>
                    <TextInput label="first name" disabled name="first_name" type="text" placeholder="" />
                    <TextInput label="last name" disabled name="last_name" type="text" placeholder="" />
                    {/* <TextInput label="middle name" name="middle_name" type="text" placeholder="" /> */}
                </div>
                <div className={styles.double}>
                    <TextInput label="phone number" disabled name="phone_number" type="tel" placeholder="" />
                    <TextInput label="email" name="email" type="email" placeholder="" />
                </div>
                <div className={styles.double}>
                    <div className={styles.customInput}>
                        <label htmlFor="">Date Of Birth</label>
                        <DatePickerInput formik={formik} field="date_of_birth" defaultValue={formik.values.date_of_birth} />
                    </div>
                    <div className={styles.customInput}>
                        <label htmlFor="">Gender</label>
                        <SelectInput formik={formik} field="gender" defaultValue={formik.values.gender} values={["male", "female"]} />
                    </div>
                </div>
                <TextInput label="location" name="location" type="text" placeholder="" />
                <div className={styles.customInput}>
                    <label htmlFor="">Display Picture</label>
                    {/* <input type="file" name="display_picture" /> */}
                    <UploadInput id="display_picture"
                        accept="image/*"
                        setImage={setDisplayPicture}
                        formik={formik} field="display_picture"
                        defaultValue={formik.values.display_picture}
                        maxCount={1} />
                </div>
            </div>
            <div className={styles.buttons}>
                <button className={styles.submit} type="submit">Update</button>
            </div>
        </>
    )
}

export default BasicInformationForm
