import Pools from './Pools'
import AddPool from './AddPool'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Confirm from './Confirm'

const Main = () => {
    const [newPool, setNewPool] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalAction, setModalAction] = useState('')
    const userInfo = useSelector(state => state.userInfo.value)

    return (
        <>
            {!newPool ?
                !userInfo.is_deleted && <Pools userInfo={userInfo} setNewPool={setNewPool} setShowModal={setShowModal} setModalAction={setModalAction} /> :
                <AddPool userInfo={userInfo} setNewPool={setNewPool} />
            }
            {showModal && <Confirm setShowModal={setShowModal} action={modalAction} />}
        </>
    )
}

export default Main
