import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Panels from './Panels'

const Main = () => {
    const [currentUser, setCurrentUser] = useState({})
    const userInfo = useSelector(state => state.userInfo.value)

    useEffect(() => {
        userInfo._id && setCurrentUser(userInfo)
    }, [userInfo, userInfo.updatedAt])

    return (
        <>
            <Panels userInfo={currentUser} />
        </>
    )
}

export default Main
