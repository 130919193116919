import { Link } from 'react-router-dom'
import styles from './styles/service.module.scss'

const Service = ({ service, serviceRef }) => {
    return (
        <>
            {!service.disabled ?
                <Link ref={serviceRef || null} id="service" className={service.disabled ? `${styles.container} ${styles.disabled}` : styles.container} to={service.slug}>
                    <span className={styles.icon}>{service.icon}</span>
                    <div className={styles.text}>
                        <h3>{service.name}</h3>
                        <p>{service.description}</p>
                    </div>
                </Link> :
                <div ref={serviceRef || null} id="service" className={service.disabled ? `${styles.container} ${styles.disabled}` : styles.container}>
                    <span className={styles.icon}>{service.icon}</span>
                    <div className={styles.text}>
                        <h3>{service.name}</h3>
                        <p>{service.description}</p>
                    </div>
                </div>
            }
        </>
    )
}

export default Service
