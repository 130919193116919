import { Link } from 'react-router-dom'
import { routes } from '../../global/utils/routeNames'
import styles from './styles/poolmembers.module.scss'
import { useEffect, useState, useRef } from 'react'
import Farmer from '../Shared/Farmer'
import FarmerModal from '../Shared/FarmerModal'
import MessageModal from './MessageModal'
import Pagination from '../Shared/Pagination'

const PoolMembers = ({ pool, userInfo, setCurrentId, setConfirmModal, setNewMember }) => {
    const [messageModal, setMessageModal] = useState(false)
    const deletePool = () => {
        setCurrentId(pool._id)
        setConfirmModal(true)
    }
    //modal functionality
    const [farmerModal, setFarmerModal] = useState(false)
    const [selectedID, setSelectedID] = useState("")
    const [modalFarmer, setModalFarmer] = useState({ })
    const closeModal = () => {
        setFarmerModal(false)
        setModalFarmer({ })
        setSelectedID("")
    }
    useEffect(() => {
        const farmerDetails = pool.members.filter(farmerDetails => farmerDetails.user_id === selectedID)
        setModalFarmer(farmerDetails[0])
    }, [selectedID, pool.members]);
    //pagination
    const [membersPerPage, setMembersPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const membersRef = useRef(null)
    const handleChange = (event, value) => {
        setPage(value)
        page !== value && membersRef.current.scrollIntoView({
            behavior: 'smooth'
        })
    }
    //Get current pool members
    const indexOfLastMember = page * membersPerPage;
    const indexOfFirstMember = indexOfLastMember - membersPerPage;
    const currentMembers = pool.members.slice(indexOfFirstMember, indexOfLastMember)

    useEffect(() => {
        setCount(Math.ceil(pool.members.length / membersPerPage))
    }, [pool.members, membersPerPage])
    return (
        <div className={styles.container}>
            <Link to={routes.institution_pools} className={styles.back}>Back</Link>
            <section className={styles.top}>
                {userInfo.is_admin && <button onClick={deletePool} className={styles.delete}>Delete</button>}
                <h3>{pool.pool_name}</h3>
                <p>No of members: <span>{pool.members?.length || 0}</span></p>
                {userInfo.is_admin && <div className={styles.buttons}>
                    <button onClick={() => setMessageModal(true)} className={styles.message}>Message</button>
                    <button onClick={() => setNewMember(true)} className={styles.add}>Add Member</button>
                </div>}
            </section>
            <div ref={membersRef} className={styles.poolmembers}>
                {pool.members?.length !== 0 &&
                    currentMembers.map((marker, index) => (
                        <Farmer key={index} marker={marker} setShowModal={setFarmerModal} setSelectedID={setSelectedID} />
                    ))
                }
            </div>

            {pool.members?.length !== 0 &&
                <div className={styles.pagination}>
                    <Pagination count={count} page={page} handleChange={handleChange} />
                </div>
            }
            {messageModal && <MessageModal setShowModal={setMessageModal} pool={pool} />}
            {(farmerModal && modalFarmer) && <FarmerModal remove poolID={pool._id} modalFarmer={modalFarmer} setShowModal={setFarmerModal} closeModal={closeModal} />}
        </div>
    )
}

export default PoolMembers
