import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLoader } from '../../state/slices/loader.slice'
import { setPoolsReload } from '../../state/slices/pools.slice'
import { addPoolApi } from './apis/addpool.api'
import styles from './styles/addpool.module.scss'

const AddPool = ({ userInfo, setNewPool }) => {
    const [pool, setPool] = useState('')
    const dispatch = useDispatch()
    const submit = async () => {
        dispatch(setLoader(true))
        const response = await addPoolApi(pool, userInfo.institution_id).then(response => {
            dispatch(setPoolsReload(true))
            dispatch(setLoader(false))
            return response
        })
        if (response && response.status === 200) {
            setPool('')
        }
    }
    return (
        <div className={styles.container}>
            <span onClick={() => setNewPool(false)} className={styles.back}>Back</span>
            <h3>Create a new pool</h3>
            <div className={styles.inputContainer} onKeyDown={({ key }) => key === 'Enter' && submit()}>
                <input
                    value={pool}
                    onChange={(e) => setPool(e.target.value)}
                    name="pool"
                    type="text" />
                <button onClick={submit}>Create</button>
            </div>
        </div>
    )
}

export default AddPool
