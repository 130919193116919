import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const aboutGsap = (container, companyheading, companytext, whyheading, whytext) => {
    const tl = gsap.timeline()
    const scrollTrigger = {
        trigger: [container.current],
        toggleActions: "restart none none reset",
        start: 'center bottom',
        // end: () => `+=${container.current.offsetHeight}`,
        // scrub: true
    }
    gsap.from([companyheading.current], {
        scrollTrigger,
        yPercent: 50,
        opacity: 0,
        ease: 'sine.out',
        duration: 0.5,
    })
    gsap.from([companytext.current], {
        scrollTrigger,
        xPercent: -100,
        opacity: 0,
        transform: 'scale(0.2)',
        ease: 'circ.out',
        duration: 0.5,
        delay: 0.5,
    })
    gsap.from([whyheading.current], {
        scrollTrigger,
        yPercent: 100,
        opacity: 0,
        ease: 'slow.out',
        duration: 0.5,
        delay: 1,
    })
    gsap.from([whytext.current], {
        scrollTrigger,
        yPercent: 100,
        opacity: 0,
        transform: 'scale(0.2)',
        ease: 'slow.out',
        duration: 0.5,
        delay: 1.5,
    })
}