import styles from './styles/director.module.scss'

const Director = ({ director, setSelectedID, setShowModal }) => {
    const handleClick = () => {
        setSelectedID(director.id)
        setShowModal(true)
    }
    return (
        <div id="director" onClick={handleClick} className={styles.container}>
            <picture>
                <img src={director.photo} alt={director.name} />
            </picture>
            <section>
                <p>{director.role}</p>
                <h5>{director.name}</h5>
            </section>
        </div>
    )
}

export default Director
