export const loginInitialValues = {
    userName: "",
    password: ""
}

export const resetInitialValues = {
    token: "",
    password: ""
}


export const forgotInitialValues = {
    phone_number: ""
}


export const endUserInitialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: ""
}

export const farmerInitialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    location: ""
}

export const institutionInitialValues = {
    first_name: "",
    phone_number: "",
    institution_rc_number: "",
    institution_location: "",
    email: "",
    password: "",
}

export const verificationInitialValues = {
    code_1: "",
    code_2: "",
    code_3: "",
    code_4: "",
    code_5: "",
    code_6: ""
}