import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ConfirmNumber from './ConfirmNumber'
import Status from './Status'
import InstitutionVerification from './forms/InstitutionVerification'

const Main = () => {
    const [confirmed, setConfirmed] = useState(false)

    const [currentUser, setCurrentUser] = useState({})
    const userInfo = useSelector(state => state.userInfo.value)

    useEffect(() => {
        userInfo._id && setCurrentUser(userInfo)
    }, [userInfo, userInfo.updatedAt])

    return (
        <>
            {currentUser.user_type === "farmer" ? <>
                {!confirmed && currentUser._id && <ConfirmNumber setConfirmed={setConfirmed} userInfo={currentUser} />}
                {confirmed && currentUser._id && <Status userInfo={currentUser} />}
            </> :
                currentUser.user_type === "institution" ? <InstitutionVerification userInfo={currentUser} /> :
                    null
            }
        </>
    )
}

export default Main
