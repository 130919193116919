import { Select } from 'antd';
import { useEffect, useState } from 'react';
import styles from '../styles/selectinput.module.scss'

const SelectInput = ({ formik, field, defaultValue, values, setType }) => {
    const { Option } = Select;
    const [currentValue, setCurrentValue] = useState('')
    async function handleChange(value) {
        (formik && field) && await formik.setFieldValue(field, value);
        setType && setType(value)
    }
    useEffect(() => {
        // handleChange(defaultValue) //todo see if it works without this
        setCurrentValue(defaultValue)
    }, [defaultValue])

    return (
        <div className={styles.container}>
            <Select className={styles.select} value={currentValue} onChange={handleChange}>
                {values && values.map((value, index) =>
                    <Option key={index} value={value}>
                        {value.charAt(0).toUpperCase() + value.substring(1)}
                    </Option>
                )}
            </Select>
        </div>
    )
}

export default SelectInput
