import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInstitutionMembers, setMembersReload } from '../../state/slices/members.slice'
import { setLoader } from '../../state/slices/loader.slice'
import Member from './Member'
import Filter from './Filter'
import { getActiveMembersApi } from './apis/getactivemembers.api'
import { getInactiveMembersApi } from './apis/getinactivemembers.api'
import styles from './styles/members.module.scss'
import _ from 'lodash'

const Members = ({ setEmployeeId, setNewMember, setShowModal, setModalAction, userInfo }) => {
    const dispatch = useDispatch()
    const members = useSelector(state => state.institutionMembers.value)
    const membersReload = useSelector(state => state.institutionMembers.reload)
    const filter = useSelector(state => state.institutionMembers.filter)

    useEffect(() => {
        dispatch(setInstitutionMembers([]))
        dispatch(setMembersReload(true))
    }, [dispatch])

    useEffect(() => {
        const getMembers = async () => {
            dispatch(setLoader(true))
            const response = filter === 'active' ? await getActiveMembersApi(userInfo.institution_id).then(response => response) :
                filter === 'inactive' ? await getInactiveMembersApi(userInfo.institution_id).then(response => response) : null
            if (response && response.status === 200) {
                dispatch(setInstitutionMembers(response.value || []))
                dispatch(setMembersReload(false))
                dispatch(setLoader(false))
            }
        }
        (userInfo.institution_id && !userInfo.is_deleted && membersReload) && getMembers()
    }, [members.length, membersReload, filter, userInfo._id, userInfo, dispatch])
    return (
        <section className={styles.container}>
            <div className={styles.top}>
                <h3>{_.capitalize(filter)}<span> Employees</span>({members.length})</h3>
                <Filter filter={filter} />
                {userInfo.is_admin && <button className={styles.add} onClick={() => setNewMember(true)} >Add <span>An Employee</span></button>}
            </div>
            <div className={styles.members}>
                {members && members.length !== 0 ?
                    <>
                        {members.map((member, index) => (
                            <Member
                                key={index}
                                member={member}
                                setShowModal={setShowModal}
                                setModalAction={setModalAction}
                                setEmployeeId={setEmployeeId}
                                filter={filter}
                                userInfo={userInfo}
                            />
                        ))
                        }
                    </> :
                    <p className={styles.noMember}>There are currently no {filter} users connected to this institution.</p>
                }
            </div>
        </section>
    )
}

export default Members
