import { useRef, useLayoutEffect } from 'react'
import styles from './styles/directors.module.scss'
import Director from './Director'
import { directorsGsap } from './animations'

const Directors = ({ team, setSelectedID, setShowModal }) => {
    const directorsRef = useRef(null)
    const directorsHeadingRef = useRef(null)
    const directorRef = useRef(null)
    useLayoutEffect(() => {
        directorsGsap(directorsRef, directorsHeadingRef, directorRef)
    }, [])
    return (
        <section ref={directorsRef} className={styles.container}>
            <h3 ref={directorsHeadingRef}>Our Directors</h3>
            <div className={styles.directors}>
                {team && team.map((director, index) => (
                    <Director key={index} directorRef={directorRef} director={director} setSelectedID={setSelectedID} setShowModal={setShowModal} />
                ))}
            </div>
        </section>
    )
}

export default Directors
