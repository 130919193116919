import { configureStore } from '@reduxjs/toolkit'
import dropdownReducer from './slices/avatardropdown.slice'
import authReducer from './slices/auth.slice'
import modalReducer from './slices/modal.slice'
import userInfoReducer from './slices/userinfo.slice'
import institutionPoolsReducer from './slices/pools.slice'
import institutionMembersReducer from './slices/members.slice'
import loaderReducer from './slices/loader.slice'
import { agroverifiedApi } from './queries/userinfo.query'
import { setupListeners } from '@reduxjs/toolkit/dist/query'

const store = configureStore({
    reducer: {
        dropdown: dropdownReducer,
        auth: authReducer,
        modal: modalReducer,
        loader: loaderReducer,
        userInfo: userInfoReducer,
        institutionPools: institutionPoolsReducer,
        institutionMembers: institutionMembersReducer,
        [agroverifiedApi.reducerPath]: agroverifiedApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(agroverifiedApi.middleware)
})

setupListeners(store.dispatch)

export default store