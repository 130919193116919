import TextInput from './TextInput'
import SelectInput from './SelectInput'
import styles from '../styles/inputscontainer.module.scss'

const RecordsForm = ({ formik }) => {
    return (
        <>
            <h3>Records <span>(All fields are required!)</span></h3>
            <div className={styles.container}>
                <div className={styles.customInput}>
                    <label htmlFor="">Do you do bookkeeping?</label>
                    <SelectInput formik={formik} field="bookkeeping"
                        defaultValue={formik.values.bookkeeping}
                        values={["yes", "no", "maybe"]} />
                </div>
                <div className={styles.customInput}>
                    <label htmlFor="">Type of bookkeeping you practice</label>
                    <SelectInput formik={formik} field="type_of_bookkeeping"
                        defaultValue={formik.values.type_of_bookkeeping}
                        values={["Cash", "Accounts Receivable/Accounts Payable", "Sales/Purchases",
                            "Payroll Expenses", "Others", "None"]} />
                </div>
                <TextInput label="where do you sell your products?" name="products_sale" type="text" placeholder="Separate multiple locations using a comma(,)" />
                <div className={styles.customInput}>
                    <label htmlFor="">Are you in a Cooperative?</label>
                    <SelectInput formik={formik} field="cooperative"
                        defaultValue={formik.values.cooperative}
                        values={["yes", "no"]} />
                </div>
                <TextInput label="If Yes, What Benefits Do You Get? Put 'None' if no benefit or not in a union"
                    name="benefits" type="text" placeholder="" />
                <div className={styles.customInput}>
                    <label htmlFor="">Do You Have Access To Seeds And Fertilizers?</label>
                    <SelectInput formik={formik} field="access_to_seeds"
                        defaultValue={formik.values.access_to_seeds}
                        values={["yes", "no"]} />
                </div>
                <div className={styles.customInput}>
                    <label htmlFor="">Have you benefited from any funding opportunity?</label>
                    <SelectInput formik={formik} field="funding"
                        defaultValue={formik.values.funding}
                        values={["yes", "no"]} />
                </div>
                <div className={styles.customInput}>
                    <label htmlFor="">Type of funding?</label>
                    <SelectInput formik={formik} field="type_of_funding"
                        defaultValue={formik.values.type_of_funding}
                        values={["grant", "loan", "none"]} />
                </div>
                <div className={styles.customInput}>
                    <label htmlFor="">If you have accessed loan or fund, How much?</label>
                    <SelectInput formik={formik} field="amount_of_fund"
                        defaultValue={formik.values.amount_of_fund}
                        values={["none", "N10,000 - N200,000", "N201,000 - N500,000", "Above N500,000"]} />
                </div>
                <div className={styles.customInput}>
                    <label htmlFor="">Which organization granted you the funding?</label>
                    <SelectInput formik={formik} field="organization"
                        defaultValue={formik.values.organization}
                        values={["Micro Finance", "Cooperative", "Commercial Bank", "Government Intervention", "CBN CACS",
                            "NIRSAL MFB", "BOA", "SMEDAN", "BOI", "Other Association"]} />
                </div>

                <div className={styles.customInput}>
                    <label htmlFor="">Do you find it easy to get technology or other technical farming tools/support?</label>
                    <SelectInput formik={formik} field="technology_support"
                        defaultValue={formik.values.technology_support}
                        values={["Not easy at all", "Fairly Easy", "Normal", "Easy", "Very Easy"]} />
                </div>
            </div>
            <div className={styles.buttons}>
                <button className={styles.submit} type="submit">Submit</button>
            </div>
        </>
    )
}

export default RecordsForm

