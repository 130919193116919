export const routes = {
    auth: '/users/auth',
    home: '/',
    about: '/about-us',
    // market: '/services/market-place',
    // dataplace: '/services/data-place',
    // caution_list: '/services/caution-list',
    contact: '/contact-us',
    // community: '/community',
    // stories: '/community/stories',
    // new_story: '/community/stories/story/new-story',
    // messages: '/user/messages',
    // dashboard: '/user/dashboard',
    profile: '/user/profile',
    verify_account: '/user/verify-account',
    institution_search: '/user/institution/search',
    institution_members: '/user/institution/employees',
    institution_pools: '/user/institution/pools',
    institution_pools_members: (pool) => `/user/institution/pools/${pool || ':id'}/members`,
    terms_of_use: '/legal/terms-of-use',
    privacy_policy: '/legal/privacy-policy'
}