import { useState } from 'react'
import { Formik, Form } from 'formik'
import { institutionVerificationValidationSchema } from '../utils/formvalidation'
import { institutionVerificationInitialValues } from '../utils/forminitialvalues'
import InstitutionVerificationForm from './InstitutionVerificationForm'
import styles from '../styles/institutionverification.module.scss'
import { setLoader } from '../../../state/slices/loader.slice'
import { useDispatch } from 'react-redux'
import { verifyInstitutionApi } from '../apis/verifyinstitution.api'

const InstitutionVerification = ({ userInfo }) => {
    const dispatch = useDispatch()
    const [cacCertificate, setCACCertificate] = useState([])
    return (
        <div className={styles.container}>
            {userInfo._id &&
                <Formik onSubmit={async (values, { resetForm, setSubmitting }) => {
                    // console.log(values)
                    dispatch(setLoader(true))
                    const response = await verifyInstitutionApi(values, userInfo.institution_id, cacCertificate).then(response => {
                        dispatch(setLoader(false))
                        setSubmitting(false)
                        return response
                    })
                    if (response.status === 200) {
                        resetForm()
                    }
                }}
                    initialValues={institutionVerificationInitialValues}
                    validationSchema={institutionVerificationValidationSchema}>

                    {formik =>
                        <Form id="institutionVerificationForm" encType="multipart/form-data">
                            <InstitutionVerificationForm
                                setCACCertificate={setCACCertificate} formik={formik} />
                        </Form>
                    }

                </Formik>}
        </div>
    )
}

export default InstitutionVerification
