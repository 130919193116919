import { useState } from 'react'
import { Formik, Form } from 'formik'
import { livestockValidationSchema } from '../utils/formvalidation'
import { livestockInitialValues } from '../utils/forminitialvalues'
import LivestockForm from './LivestockForm'
import styles from '../styles/updateprofile.module.scss'
import { useDispatch } from 'react-redux'
import { setLoader } from '../../../state/slices/loader.slice'
import { productsApi } from '../api/products.api'

const Livestock = ({ userInfo, setTab }) => {
    const dispatch = useDispatch()
    const [livestockImage, setLivestockImage] = useState([])
    return (
        <div className={styles.container}>
            {userInfo._id &&
                <Formik onSubmit={async (values, { resetForm, setSubmitting }) => {
                    dispatch(setLoader(true))
                    const response = await productsApi('livestock', values, livestockImage).then(response => {
                        dispatch(setLoader(false))
                        setSubmitting(false)
                        return response
                    })
                    if (response.status === 200) {
                        resetForm()
                        // setTab(0)
                    }
                }}
                    initialValues={livestockInitialValues}
                    validationSchema={livestockValidationSchema}>

                    {formik =>
                        <Form encType="multipart/form-data" id="livestockForm">
                            <LivestockForm setLivestockImage={setLivestockImage} formik={formik} />
                        </Form>
                    }

                </Formik>}
        </div>
    )
}

export default Livestock
