import TextInput from './TextInput'
import styles from '../styles/inputscontainer.module.scss'
import UploadInput from './UploadInput'

const CropsForm = ({ formik, setCropImage }) => {
    return (
        <>
            <div className={styles.container}>
                <TextInput label="name of crop"
                    name="crop_name" type="text" placeholder="" />
                <TextInput label="use of crops"
                    name="crop_use" type="text" placeholder="" />
                <TextInput label="Average time for crops to grow"
                    name="grow_time" type="text" placeholder="" />
                {/* <TextInput label="fertilizer application type"
                            name="crops_fertilizer_type" type="text"
                            placeholder="" /> */}
                <TextInput label="space per crop"
                    name="crop_space" type="text"
                    placeholder="How much space per crop?" />
                <div className={styles.customInput}>
                    <label htmlFor="">Picture of Crop</label>
                    <UploadInput formik={formik}
                        accept="image/*"
                        multiple
                        setImage={setCropImage}
                        maxCount={1} field="crop_pictures" defaultValue={formik.values.crop_pictures} />
                </div>
            </div>
            <div className={styles.buttons}>
                <button className={styles.submit} type="submit">Submit</button>
            </div>
        </>
    )
}

export default CropsForm

