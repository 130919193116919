import http from '../../http-common'
import Cookies from "js-cookie";

export const loggedInInstitutionApi = async (institutionId) => {
    const currentUser = Cookies.get('auth') || "{}"
    const token = JSON.parse(currentUser).data?.token.token
    const request = {
        method: 'GET',
        url: "/institution/info/" + institutionId,
        headers: {
            "Accept": "application/json",
            authorization: 'Bearer ' + token
        }
    };
    try {
        let response = await http({
            ...request
        }).catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log(err.response)
            } else if (err.request) {
                // client never received a response, or request never left
                console.log(err.request)
            } else {
                // anything else
                console.log(err)
            }
        })

        if (response && response.status === 200) {
            const data = response.data.data
            return response = {
                value: data,
                status: 200
            }
        }
    } catch (e) {
        console.log(e)
    }
}