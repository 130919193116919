import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);


export const getInTouchGsap = (container, heading, paragraph, form) => {
    const tl = gsap.timeline()
    const scrollTrigger = {
        trigger: [container.current],
        toggleActions: "play none none none",
        start: 'center bottom',
        // end: () => `+=${container.current.offsetHeight}`,
        // scrub: true
    }
    gsap.from([heading.current], {
        scrollTrigger,
        xPercent: -50,
        opacity: 0,
        duration: 0.5,
        ease: 'power3.out'
    })
    gsap.from([paragraph.current], {
        scrollTrigger,
        xPercent: 50,
        opacity: 0,
        duration: 0.5,
        delay: 0.5,
        ease: 'power3.out'
    })
    gsap.from([form.current], {
        scrollTrigger,
        yPercent: 100,
        opacity: 0,
        duration: 0.5,
        delay: 1,
        transform: 'scale(0.2)',
        ease: 'power3.out'
    })
}