import http from '../../http-common'
import _ from 'lodash'
import Cookies from "js-cookie";
import { loggedInInstitutionApi } from './loggedInInstitutionApi.api';

export const loggedInUserApi = async () => {
    const currentUser = Cookies.get('auth') || "{}"
    const token = JSON.parse(currentUser).data?.token.token
    const request = {
        method: 'GET',
        url: "/user/me/dashboard",
        headers: {
            "Accept": "application/json",
            authorization: 'Bearer ' + token
        }
    };
    try {
        let response = await http({
            ...request
        }).catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log(err.response)
            } else if (err.request) {
                // client never received a response, or request never left
                console.log(err.request)
            } else {
                // anything else
                console.log(err)
            }
        })

        if (response && response.status === 200) {
            const data = response.data.data
            const { password, ...values } = data
            values.first_name = _.capitalize(values.first_name)
            values.last_name = values.last_name ? _.capitalize(values.last_name) : ''

            const institutionResponse = (values.institution_id && values.is_admin && !values.is_deleted) && await loggedInInstitutionApi(values.institution_id).then(response => response)
            if (institutionResponse && institutionResponse.status === 200) {
                const institution = institutionResponse.value || {}
                values.institution = institution
            }
            return response = {
                value: values,
                status: 200
            }
        }
    } catch (e) {
        console.log(e)
    }
}