import Pagination from '@material-ui/lab/Pagination';
import styles from './styles/pagination.module.scss'

export default function PaginationControl({ count, page, handleChange }) {

    return (
        <div className={styles.container}>
            <Pagination count={count} page={page} onChange={handleChange} />
        </div>
    );
}