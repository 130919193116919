import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import styles from './styles/avatarmenu.module.scss';
import { routes } from '../../global/utils/routeNames';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    avatarRoot: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    paper: {
        // marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

export default function MenuListComposition({ userInfo, logOut }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <div className={styles.container}>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <span className={styles.user}>
                        {(userInfo.photo_url || userInfo.institution?.institution_logo_url) ?
                            <Avatar className={styles.avatar} alt={userInfo.first_name} src={userInfo.photo_url || userInfo.institution?.institution_logo_url} /> :
                            <Avatar className={styles.avatar}>{userInfo.first_name?.charAt(0)}</Avatar>
                        }
                        <span className={styles.userName}>{userInfo.first_name}</span>
                    </span>
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper className={classes.paper}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={handleClose}>
                                            <NavLink to={routes.profile} activeClassName={styles.active}>
                                                <span>Profile</span>
                                            </NavLink>
                                        </MenuItem>
                                        {/* <MenuItem onClick={handleClose}>
                                            <NavLink to={routes.messages} activeClassName={styles.active}>
                                                <span>Messages</span>
                                            </NavLink>
                                        </MenuItem> */}
                                        <MenuItem onClick={handleClose}>
                                            <span className={styles.logOut} onClick={logOut}>
                                                <span>Log Out</span>
                                            </span>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}