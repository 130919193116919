import { createPortal } from 'react-dom'
import styles from './styles/confirm.module.scss'

const TokenModal = ({ setShowModal, tokenCaution, formik }) => {

    return createPortal(
        <div className={styles.container}>
            <div className={`${styles.box} ${styles.token}`}>
                <h4>Token Caution</h4>
                <p>There are currently active tokens in this pool, sending new ones will deactivate the previous batch. Send Anyway?</p>
                <div className={styles.buttons}>
                    <button type="button" onClick={() => setShowModal(false)} className={styles.cancel}>Cancel</button>
                    <button type={tokenCaution ? "submit" : "button"} onClick={tokenCaution ? formik.handleSubmit : null} className={styles.act} disabled={formik.isSubmitting}>Send</button>
                </div>
            </div>
        </div>
        , document.getElementById('extra'))
}

export default TokenModal
