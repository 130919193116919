import { IoMdClose } from 'react-icons/io'
import { createPortal } from 'react-dom'
import styles from './styles/avatarenlarged.module.scss'
import { useRef } from 'react'

const AvatarEnlarged = ({ setShowModal, src, alt }) => {
    const modalRef = useRef()
    const closeModal = () => {
        setShowModal(false)
    }
    const closeModalTarget = (e) => {
        if (modalRef.current === e.target) {
            closeModal()
        }
    }
    return createPortal(
        <div ref={modalRef} onClick={closeModalTarget} className={styles.container}>
            <div className={styles.modalContent}>
                <span onClick={closeModal} className={styles.close}><IoMdClose /></span>
                <picture>
                    <img src={src} alt={alt || ''} />
                </picture>
            </div>
        </div>
        , document.getElementById('extra'))
}

export default AvatarEnlarged
