// import { RiDashboardFill } from 'react-icons/ri'
import { IoMdPerson } from 'react-icons/io'
// import { GiDiscussion } from 'react-icons/gi'
// import { BiMessageDetail } from 'react-icons/bi'
import { MdVerifiedUser } from 'react-icons/md'
import { FaDatabase, FaUsers } from 'react-icons/fa'
import { GiFiles } from 'react-icons/gi'
import { routes } from "../../../global/utils/routeNames";

export const dashboardAsideLinks = [
    //     {
    //     id: 1,
    //     userType: 'all',
    //     icon: <RiDashboardFill />,
    //     name: 'dashboard',
    //     slug: routes.dashboard
    // },
    {
        id: 2,
        userType: 'all',
        icon: <IoMdPerson />,
        name: 'profile',
        slug: routes.profile
    },
    // {
    //     id: 3,
    //     userType: 'all',
    //     icon: <GiDiscussion />,
    //     name: 'community',
    //     slug: routes.community
    // },
    // {
    //     id: 4,
    //     userType: 'all',
    //     icon: <BiMessageDetail />,
    //     name: 'messages',
    //     slug: routes.messages
    // },
    {
        id: 5,
        userType: ['farmer', 'institution'],
        icon: <MdVerifiedUser />,
        name: 'verify account',
        slug: routes.verify_account
    }, {
        id: 6,
        userType: ['institution'],
        icon: <FaDatabase />,
        name: 'search',
        slug: routes.institution_search
    }, {
        id: 7,
        userType: ['institution'],
        icon: <FaUsers />,
        name: 'employees',
        slug: routes.institution_members
    }, {
        id: 8,
        userType: ['institution'],
        icon: <GiFiles />,
        name: 'pools',
        slug: routes.institution_pools
    }
]
