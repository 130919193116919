import Avatar from '@material-ui/core/Avatar';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { GoVerified } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { routes } from '../../global/utils/routeNames';
import styles from './styles/profile.module.scss'

const Profile = ({ userInfo }) => {
    const [userData, setUserData] = useState({})
    useEffect(() => {
        const setData = () => {
            const data = {
                agroID: userInfo.agro_id,
                id: userInfo._id,
                isVerified: userInfo.is_verified || userInfo.institution?.is_verified,
                fullName: userInfo.institution?.institution_name || userInfo.first_name + " " + userInfo.last_name,
                phoneNumber: userInfo.phone_number,
                email: userInfo.institution?.institution_email || userInfo.email,
                gender: userInfo.gender,
                dateOfBirth: userInfo.date_of_birth,
                location: userInfo.location || userInfo.institution?.institution_location,
                userType: userInfo.user_type?.split('_').join(' '),
                photo_url: userInfo.photo_url || userInfo.institution?.institution_logo_url,
                farmerType: userInfo.type_of_farmer,
                rc_number: userInfo.institution?.institution_rc_number,
                head_office_address: userInfo.institution?.head_office_address
            }
            setUserData(data)
        }
        userInfo._id && setData()
    }, [userInfo])
    return (
        <>
            {userData.id && <div className={styles.container}>
                <div className={styles.top}>
                    {
                        userData.photo_url ?
                            <Avatar className={styles.avatar} alt={userData.fullName} src={userData.photo_url} /> :
                            <Avatar className={styles.avatar}>{userData.fullName?.charAt(0)}</Avatar>
                    }
                    <div className={styles.center}>
                        <h3>{userData.fullName}</h3>
                        {userData.userType && <p className={styles.category}>Category: <span>{userData.userType}</span></p>}
                        {userData.location && <p className={styles.location}>Location: <span>{userData.location}</span></p>}
                        {userData.isVerified && userData.userType === "farmer" && <p className={styles.id}>AgroID: <span>{userData.agroID}</span><span></span></p>}
                    </div>
                    <div className={styles.right}>
                        {userData.isVerified && <p><span className={styles.icon}><GoVerified /></span>
                            <span className={styles.text}>Verified</span></p>}
                        {userData.userType === "institution" && <Link to={routes.institution_members}><button className={styles.add} type="button">View Members</button></Link>}
                    </div>
                </div>
                <div className={styles.bottom}>
                    <h4>Additional Information</h4>
                    <div className={styles.information}>
                        {[
                            {
                                name: 'Email',
                                item: userData.email
                            }, {
                                name: 'Phone Number',
                                item: userData.phoneNumber
                            }, {
                                name: 'Date Of Birth',
                                item: userData.dateOfBirth && dayjs(userData.dateOfBirth).format('DD MMM YYYY')
                            }, {
                                name: 'Gender',
                                item: userData.gender
                            }, {
                                name: 'Farming Type',
                                item: userData.farmerType
                            }, {
                                name: "RC Number",
                                item: userData.rc_number
                            }, {
                                name: "Head Office",
                                item: userData.head_office_address
                            }]
                            .map((item, index) => (
                                item.item && <div className={styles.item} key={index}>
                                    <h5>{item.name}</h5>
                                    <p>{item.item}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default Profile
