import { useDispatch, useSelector } from 'react-redux'
import { setPoolsReload } from '../../state/slices/pools.slice'
import Pool from './Pool'
import styles from './styles/pools.module.scss'
import PoolSearch from './PoolSearch'
import { useEffect } from 'react'

const Members = ({ userInfo, setNewPool, setShowModal, setModalAction }) => {
    const dispatch = useDispatch()
    const pools = useSelector(state => state.institutionPools.value)


    useEffect(() => {
        dispatch(setPoolsReload(true))
    }, [dispatch])


    return (
        <section className={styles.container}>
            <div className={styles.top}>
                <h3><span>Institution</span> Pools({pools.length})</h3>
                {userInfo.is_admin && <button onClick={() => setNewPool(true)}>Create <span>A New Pool</span></button>}
            </div>
            <PoolSearch />
            <div className={styles.pools}>
                {pools && pools.length !== 0 ?
                    <>
                        {pools.map((pool, index) => (
                            <Pool key={index} userInfo={userInfo} button="delete" pool={pool} setShowModal={setShowModal} setModalAction={setModalAction} />
                        ))}
                    </>
                    :
                    <p className={styles.noPool}>You haven't created a pool yet.</p>
                }
            </div>
        </section>
    )
}

export default Members
