import { Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
// import { getFileUrl } from '../api/farmland.api';
// import { useDispatch } from 'react-redux'
// import { setLoader } from '../../../state/slices/loader.slice'

const UploadInput = ({ accept, action, id, formik, field, defaultValue, maxCount, multiple, setUrls, setImage }) => {
    const [currentValue, setCurrentValue] = useState([])
    // const dispatch = useDispatch()
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const props = {
        accept: accept ? accept : '',
        id: id ? id : '',
        multiple: multiple && multiple,
        maxCount: maxCount ? Number(maxCount) : 0,
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
                !action && formik.setFieldValue(field, info.fileList)
                !action && setImage(prev => info.fileList.map(item => item.originFileObj))
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        customRequest(info) {
            !action && dummyRequest(info)
            // const getUrls = () => {
            //     dispatch(setLoader(true))
            //     getFileUrl(info.file, action).then(response => {
            //         dispatch(setLoader(false))
            //         info.onSuccess('ok')
            //         if (response && response.status === 201) {
            //             const data = response.value
            //             setUrls(prev => ([...prev, data]))
            //         }
            //         return response
            //     })
            // }
            // action && getUrls()
        }
    };
    useEffect(() => {
        setCurrentValue(defaultValue)
    }, [defaultValue, maxCount])
    return (
        <Upload {...props} defaultFileList={currentValue}>
            <Button icon={<UploadOutlined />}>Add File</Button>
        </Upload>)
}

export default UploadInput