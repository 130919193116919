import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styles from './styles/tabs.module.scss'
import './styles/overridetabs.scss'

export default function CenteredTabs({ tab, setTab, userInfo }) {

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Paper className={styles.container}>
            <Tabs
                value={tab}
                onChange={handleChange}
                // centered
                variant="fullWidth"
                scrollButtons="auto"
            >
                <Tab label="Overview" />
                {userInfo.user_type !== "institution" ?
                    <Tab label="Personal" /> :
                    <Tab label="" disabled />}
                {userInfo.user_type === "farmer" ?
                    <Tab label="Farmland" /> :
                    <Tab label="" disabled />}
                {userInfo.user_type === "farmer" ?
                    <Tab label="Products" /> :
                    <Tab label="" disabled />}
                {userInfo.user_type === "farmer" ?
                    <Tab label="Records" /> :
                    <Tab label="" disabled />}
            </Tabs>
        </Paper>
    );
}