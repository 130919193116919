import TextInput from './TextInput'
import styles from '../styles/inputscontainer.module.scss'
import UploadInput from './UploadInput'

const LivestockForm = ({ formik, setLivestockImage }) => {
    return (
        <>
            <div className={styles.container}>
                <TextInput label="name of livestock"
                    name="livestock_name" type="text" placeholder="List all livestock you farm. Separated by a comma(,)" />
                <TextInput label="use of livestock"
                    name="livestock_use" type="text" placeholder="" />
                <TextInput label="treatment"
                    name="treatment" type="text"
                    placeholder="List the treatments you apply on livestock. Separated by a comma(,)" />
                <TextInput label="Average time for livestock to grow"
                    name="grow_time" type="text" placeholder="" />
                <div className={styles.customInput}>
                    <label htmlFor="">Picture of livestock</label>
                    <UploadInput formik={formik}
                        setImage={setLivestockImage}
                        multiple
                        maxCount={1} field="livestock_pictures" defaultValue={formik.values.livestock_pictures} />
                </div>
            </div>
            <div className={styles.buttons}>
                <button className={styles.submit} type="submit">Submit</button>
            </div>
        </>
    )
}

export default LivestockForm

