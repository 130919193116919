// import { HashLink } from 'react-router-hash-link'
import { Link } from 'react-router-dom';
import { TiSocialFacebook, TiSocialTwitter, TiSocialInstagram, TiSocialLinkedin, TiSocialYoutube } from 'react-icons/ti'
import Services from './Services'
import styles from './styles/footer.module.scss';
import { routes } from '../../global/utils/routeNames';

const Footer = () => {
    return (
        <footer className={styles.container}>
            <div className={styles.footertop}>
                <section className={styles.agroverified}>
                    <img src="https://res.cloudinary.com/agroverifiednig/image/upload/v1625915203/website/logos/logo_full_color.png" alt="logo" />
                    <div>
                        <p>Agroverified is a leading integrity ecosystem for agri-business across the world, we provide verified information on farmer’s products, farmlands, farmer’s geo-location, and crop types for transparent business transactions on a secure digital platform.</p>
                    </div>
                    <div className={styles.icons}>
                        <a href="https://web.facebook.com/agroverified/" target="blank" rel="noopener noreferrer">
                            <span><TiSocialFacebook /></span>
                        </a>
                        <a href="https://twitter.com/agroverified" target="blank" rel="noopener noreferrer">
                            <span><TiSocialTwitter /></span>
                        </a>
                        <a href="https://www.instagram.com/agroverified/" target="blank" rel="noopener noreferrer">
                            <span><TiSocialInstagram /></span>
                        </a>
                        <a href="https://www.youtube.com/channel/UCr8tTTSVzIwcejmyf1zITXw" target="blank" rel="noopener noreferrer">
                            <span><TiSocialYoutube /></span>
                        </a>
                        <a href="https://www.linkedin.com/company/agroverifiedng/" target="blank" rel="noopener noreferrer">
                            <span><TiSocialLinkedin /></span>
                        </a>
                    </div>
                </section>
                <section className={styles.quicklinks}>
                    <h4>Quick Links</h4>
                    <div>
                        <ul>
                            {/* <Link to={routes.market}>Market</Link> */}
                            {/* <Link to={routes.dataplace}>Dataplace</Link> */}
                            {/* <Link to={routes.community}>Community</Link> */}
                            <Link to={routes.about}>About</Link>
                            <Link to={routes.verify_account}>Verification</Link>
                        </ul>
                        <ul>
                            <Link to={routes.contact}>Contact</Link>
                            {/* <Link to={routes.messages}>Messages</Link> */}
                            {/* <Link to={routes.dashboard}>Dashboard</Link> */}
                            <Link to={routes.profile}>Profile</Link>
                            {/* <Link to={routes.caution_list}>Caution List</Link> */}
                        </ul>
                    </div>
                </section>
                <section className={styles.contactus}>
                    <h4>Contact Us</h4>
                    <div>
                        <p>We are always happy to hear from you, reach us through any of these media platforms.</p>
                        <p>Addresses: <a title="Plot 18, Block 15 Bashorun Okusanya Avenue off Admiralty way, Lekki Phase 1" href="https://goo.gl/maps/poxGvVV3egM8tegR6" target="_blank" rel="noopener noreferrer">Lagos, Nigeria</a> | <a title="Suite 12, 3rd Floor, Candelux plaza, Adjacent National Open University, Dutse Junction, Kubwa Express, Abuja FCT" href="https://goo.gl/maps/Qed5NNK2z2tLNadb6" target="_blank" rel="noopener noreferrer">Abuja, Nigeria</a></p>
                        <p>Email: <a href="mailto:info@agroverified.com">info@agroverified.com</a> | <a href="mailto:support@agroverified.com">support@agroverified.com</a></p>
                        <p>Phone: <a href="tel:+2347032111095">+234 703 211 1095</a></p>
                        <p>Open hours: <span>Mon - Fri: 8 AM to 5 PM</span></p>
                        {/* <p>Website: <HashLink smooth to="/#">www.agroverified.com</HashLink></p> */}
                    </div>
                </section>
            </div>
            <div className={styles.footerbottom}>
                <p className={styles.copyright}>Copyright &copy;{new Date().getFullYear()} agroverified.com</p>
                <nav>
                    <Link to={routes.home}><span>Home</span></Link>
                    <Link to={routes.about}><span>About</span></Link>
                    {/* <Services /> */}
                    {/* <Link to={routes.community}><span>Community</span></Link> */}
                    <Link to={routes.contact}><span>Contact</span></Link>
                </nav>
            </div>
        </footer>
    )
}

export default Footer
