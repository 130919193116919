import { useState } from 'react'
import styles from '../styles/inputscontainer.module.scss'
import Crops from './Crops'
import Livestock from './Livestock'
import SelectInput from './SelectInput'

const Products = ({ userInfo, setTab }) => {
    const [type, setType] = useState('')
    return (
        <div className={`${styles.container} ${styles.products}`}>
            <h3>Products</h3>
            <div className={styles.customInput}>
                <label htmlFor="">product type</label>
                <SelectInput defaultValue={type} setType={setType}
                    values={["Crop", "Livestock"]} />
            </div>
            {type === "Livestock" && <Livestock userInfo={userInfo} setTab={setTab} />}
            {type === "Crop" && <Crops userInfo={userInfo} setTab={setTab} />}
        </div>
    )
}

export default Products
