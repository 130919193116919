import swal from "sweetalert";
import http from '../../../http-common'

export const institutionApi = async (logo) => {
    const form = document.getElementById('institutionLoginForm')
    const formData = new FormData(form)
    formData.delete("terms-conditions")
    logo[0] && formData.append('institution_logo', logo[0])
    const request = {
        method: 'POST',
        url: "/institution/create-institution",
        headers: {
            "Content-Type": undefined,
        },
        data: formData
        // data: JSON.stringify(values)
    };
    try {
        let response = await http({
            ...request
        }).catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log(err.response)
                swal({
                    icon: 'error',
                    text: err.response.data.error || err.response.data.message,
                    timer: 4000,
                    button: false,
                });
            } else if (err.request) {
                // client never received a response, or request never left
                console.log(err.request)
            } else {
                // anything else
                console.log(err)
            }
        })

        if (response && response.status === 200) {
            const data = response.data
            swal({
                icon: 'success',
                text: data.message,
                timer: 4000,
                button: false,
            });
            return response = {
                value: data,
                status: 200
            }
        }
    } catch (e) {
        console.log(e)
    }
}