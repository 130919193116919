import http from '../../../http-common'

export const agropreneursApi = async () => {
    const request = {
        method: 'GET',
        url: "/user"
    }
    try {
        let response = await http({
            ...request
        }).catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log(err.response)
            } else if (err.request) {
                // client never received a response, or request never left
                console.log(err.request)
            } else {
                // anything else
                console.log(err)
            }
        })
        if (response && response.status === 200) {
            const data = response.data.data
            const farmers = data.filter(user => user.user_type === 'farmer' && user.photo_url)
            return farmers
        }
    } catch (e) {
        console.log(e)
    }
}