import http from '../../../http-common'
import swal from "sweetalert";
import Cookies from "js-cookie";

export const addToPoolApi = async (poolId, farmerDetails) => {
    const currentUser = Cookies.get('auth') || "{}"
    const token = JSON.parse(currentUser).data?.token.token
    const request = {
        method: 'PATCH',
        url: "/pool/update/" + poolId,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            authorization: 'Bearer ' + token
        },
        data: JSON.stringify(farmerDetails)
    };
    try {
        let response = await http({
            ...request
        }).catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log(err.response)
                swal({
                    icon: 'error',
                    text: err.response.data.error || err.response.data.message,
                    timer: 4000,
                    button: false,
                });
            } else if (err.request) {
                // client never received a response, or request never left
                console.log(err.request)
            } else {
                // anything else
                console.log(err)
            }
        })

        if (response && response.status === 200) {
            const data = response.data
            swal({
                icon: 'success',
                text: data.message,
                timer: 4000,
                button: false,
            });
            return response = {
                value: data.data,
                status: 200
            }
        }
    } catch (e) {
        console.log(e)
    }
}
