import { Formik, Form } from 'formik'
import SignUpBottom from './SignUpBottom'
import TextInput from './TextInput'
import PasswordInput from './PasswordInput'
import styles from './styles/signup.module.scss'
import { institutionInitialValues } from './utils/forminitialvalues'
import { institutionValidationSchema } from './utils/formvalidation'
import UploadInput from './UploadInput'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLoader } from '../../state/slices/loader.slice'
import { institutionApi } from './apis/institution.api'

const InstitutionForm = ({ userType, setVerify }) => {
    const [logo, setLogo] = useState([])
    const dispatch = useDispatch()
    return (
        <div className={styles.container}>
            <Formik
                initialValues={institutionInitialValues}
                validationSchema={institutionValidationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    dispatch(setLoader(true))
                    const response = await institutionApi(logo).then(response => {
                        dispatch(setLoader(false))
                        setSubmitting(false)
                        return response
                    })
                    if (response.status === 200) {
                        resetForm()
                        setVerify(true)
                    }
                }}
            >
                {formik =>
                    <Form className={styles.form} id="institutionLoginForm" encType="multipart/form-data">
                        <div className={styles.doubleDiv}>
                            <TextInput autoFocus type='text' label='Institution Name' name='first_name' />
                            <TextInput type='tel' label='Phone Number' name='phone_number' placeholder="enter admin phone number +(country code)" />
                        </div>
                        <div className={styles.doubleDiv}>
                            <TextInput type='text' label='RC Number' name='institution_rc_number' />
                            <TextInput type='text' label='Location' name='institution_location' />
                        </div>
                        <div className={styles.doubleDiv}>
                            <TextInput type='text' label='Email Address' name='email' />
                            <PasswordInput label='Password' name='password' />
                        </div>
                        <div className={styles.customInput}>
                            <label htmlFor="">Upload Logo</label>
                            <UploadInput id="institution_logo"
                                accept="image/*"
                                formik={formik}
                                setImage={setLogo}
                                field="institution_logo"
                                defaultValue={formik.values.institution_logo}
                                maxCount={1} />
                        </div>
                        <SignUpBottom formik={formik} />
                    </Form>
                }
            </Formik>
        </div>
    )
}

export default InstitutionForm
