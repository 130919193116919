import { useRef, useLayoutEffect } from 'react'
import { aboutGsap } from './animations'
import styles from './styles/about.module.scss'

const About = () => {
    const aboutRef = useRef(null)
    const aboutCompanyHeadingRef = useRef(null)
    const aboutCompanyTextRef = useRef(null)
    const aboutWhyHeadingRef = useRef(null)
    const aboutWhyTextRef = useRef(null)
    useLayoutEffect(() => {
        aboutGsap(aboutRef, aboutCompanyHeadingRef, aboutCompanyTextRef, aboutWhyHeadingRef, aboutWhyTextRef)
    }, [])
    return (
        <section ref={aboutRef} className={styles.container}>
            <h3 ref={aboutCompanyHeadingRef}>Our Company</h3>
            <div ref={aboutCompanyTextRef}>
                <p>At AgroVerified we are building an integrity ecosystem and a transparent platform for agribusiness across the world to facilitate trade and minimize (reduce) fraud in the Agricultural sector.</p>
                <p>We set out with a firm determination to ensure that investing in agribusiness should not be limited by fraud and lack of transparency and that Anyone across the world should easily trade with authentic agricultural information of farmers and farm products.</p>
                <p>Today, our product houses over 200,000 verified farmers across Africa with a wide range of access to trade partners, raw material suppliers, customers (farmers), grant beneficiaries, product capacity reports, caution lists, and many more.</p>
                <p>By combining Farmer’s and farm validation with our verification software, Agroverified offers the best verification solution for agribusiness, enabling transparent trade across the agriculture sector.</p>
            </div>
            <section>
                <h4 ref={aboutWhyHeadingRef}>Why Us?</h4>
                <div ref={aboutWhyTextRef}>
                    <p>Agriculture in Africa is a sector mostly run by smallholder farmers who are fully operating on a subsistence scale of farming. These operations are faced with a lot of challenges such as access to funds, tools, materials, and large-scale buyers of products due to limited verified information about farmers, farmlands, and their products, fraudulent activities, large percentage payment to third parties, logistics problems, and a host of others.</p>
                    <p>Agroverified will give Instant access to verified farmers, aggregators, and producers. Connect farmers to business opportunities, connect institutions to small scale farmers across the globe with ease of identification and KYC.</p>
                </div>
            </section>
        </section>
    )
}

export default About
