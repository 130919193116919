export const basicInitialValues = {
    first_name: "",
    // middle_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    date_of_birth: "",
    gender: "",
    location: "",
}

export const farmlandInitialValues = {
    farmland_image: [],
    size: '',
    capacity: '',
    type_of_farming: '',
    ownership: '',
    document_type_label: '',
    document_type_url: '',
    duration_of_ownership: '',
    duration_on_farmland: ''
}

export const cropInitialValues = {
    crop_name: '',
    crop_use: '',
    grow_time: '',
    crop_space: '',
    crop_pictures: []
}

export const livestockInitialValues = {
    livestock_name: '',
    livestock_use: '',
    treatment: '',
    grow_time: '',
    livestock_pictures: []
}

export const recordsInitialValues = {
    bookkeeping: '',
    type_of_bookkeeping: '',
    products_sale: '',
    cooperative: '',
    benefits: '',
    access_to_seeds: '',
    funding: '',
    type_of_funding: '',
    amount_of_fund: '',
    organization: '',
    technology_support: ''
}