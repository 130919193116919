import * as Yup from 'yup'

export const basicValidationSchema = Yup.object().shape({
    first_name: Yup.string().required("first name is required"),
    // middle_name: Yup.string(),
    last_name: Yup.string().required("last name is required"),
    email: Yup.string().email('invalid email address'),
    phone_number: Yup.string()
        .matches('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,7}$',
            { message: "invalid phone number" })
        .required('phone number is required'),
    date_of_birth: Yup.string(),
    gender: Yup.string(),
    location: Yup.string(),
})

export const farmlandValidationSchema = Yup.object().shape({
    size: Yup.string().required('field is required'),
    capacity: Yup.string().required('field is required'),
    type_of_farming: Yup.string().required('field is required'),
    ownership: Yup.string().required('field is required'),
    document_type_label: Yup.string().required('field is required'),
    // document_type_url: Yup.string().required('field is required'),
    // duration_of_ownership: Yup.string().required('field is required'),
    duration_on_farmland: Yup.string().required('field is required')
})

export const cropValidationSchema = Yup.object().shape({
    crop_name: Yup.string().required('field is required'),
    crop_use: Yup.string().required('field is required'),
    grow_time: Yup.string().required('field is required'),
    crop_space: Yup.string().required('field is required'),
})
export const livestockValidationSchema = Yup.object().shape({
    livestock_name: Yup.string().required('field is required'),
    livestock_use: Yup.string().required('field is required'),
    treatment: Yup.string().required('field is required'),
    grow_time: Yup.string().required('field is required'),
})

export const recordsValidationSchema = Yup.object().shape({
    bookkeeping: Yup.string().required('field is required'),
    type_of_bookkeeping: Yup.string().required('field is required'),
    products_sale: Yup.string().required('field is required'),
    cooperative: Yup.string().required('field is required'),
    benefits: Yup.string().required('field is required'),
    access_to_seeds: Yup.string().required('field is required'),
    funding: Yup.string().required('field is required'),
    type_of_funding: Yup.string().required('field is required'),
    amount_of_fund: Yup.string().required('field is required'),
    organization: Yup.string().required('field is required'),
    technology_support: Yup.string().required('field is required')
})
