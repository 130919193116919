import About from './About'
import Directors from './Directors'
import Modal from './Modal'
import Statements from './Statements'
import { team } from './utils/team'
import { useState, useEffect } from 'react'
import styles from './styles/main.module.scss'

const Main = () => {
    const [showModal, setShowModal] = useState(false)
    const [selectedID, setSelectedID] = useState(0)
    const [modalDirector, setModalDirector] = useState({ })
    const closeModal = () => {
        setShowModal(false)
        setModalDirector({ })
        setSelectedID(0)
    }
    useEffect(() => {
        const director = team.filter(director => director.id === selectedID)
        setModalDirector(director[0])
    }, [selectedID]);
    return (
        <div className={styles.container}>
            <About />
            <Statements />
            <Directors team={team} setSelectedID={setSelectedID} setShowModal={setShowModal} />
            {(showModal && modalDirector) && <Modal modalDirector={modalDirector} setShowModal={setShowModal} closeModal={closeModal} />}
        </div>
    )
}

export default Main
