import styles from './styles/status.module.scss'

const Status = ({ userInfo }) => {
    return (
        <div className={styles.container}>
            {userInfo._id &&
                <div className={styles.status}>
                    <h4>Status: <span>{userInfo.is_verified ? 'Verified' : 'Not Verified'}</span></h4>
                    <div className={styles.instructions}>
                        <p>Instructions:</p>
                        <ol>
                            <li>Fill farmland form on your profile.</li>
                            <li>Be sure to fill the form with valid information.</li>
                            <li>Submit to begin the verification process.</li>
                            <li>You will get notified upon verification.</li>
                        </ol>
                    </div>
                </div>}
        </div>
    )
}

export default Status
