import { useHistory } from 'react-router'
import styles from './styles/main.module.scss'

const Main = () => {
    const history = useHistory()
    return (
        <div className={styles.container}>
            <h3>PAGE NOT FOUND</h3>
            <button onClick={() => history.goBack()}>Go Back</button>
        </div>
    )
}

export default Main
