import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        showModal: false,
        status: ""
    },
    reducers: {
        setShowModal: (state, { payload }) => {
            state.showModal = payload
            if (state.showModal === true) {
                document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
            } else {
                document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
                document.getElementsByTagName('body')[0].classList.add('overflow-auto');
            }
        },
        setStatus: (state, { payload }) => {
            state.status = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setShowModal, setStatus } = modalSlice.actions

export default modalSlice.reducer