import { useState, useRef, useLayoutEffect } from 'react'
import Login from './Login'
import Text from './Text'
import styles from './styles/main.module.scss'
import ResetPassword from './ResetPassword'
import ForgotPassword from './ForgotPassword'
import { mainGsap } from './animations'

const Main = () => {
    const [authState, setAuthState] = useState('login')
    const authRef = useRef(null)
    const authTextRef = useRef(null)
    const authFormRef = useRef(null)
    useLayoutEffect(() => {
        mainGsap(authRef, authTextRef, authFormRef)
    }, [])
    return (
        <div ref={authRef} className={styles.container}>
            <div ref={authTextRef} className={styles.left}>
                <Text />
            </div>
            <div ref={authFormRef} className={styles.right}>
                {authState === 'login' ? <Login setAuthState={setAuthState} /> :
                    authState === 'forgot' ? <ForgotPassword setAuthState={setAuthState} /> :
                        authState === 'reset' ? <ResetPassword setAuthState={setAuthState} /> :
                            null}
            </div>
        </div>
    )
}

export default Main
