import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setLoader } from '../../state/slices/loader.slice'
import { searchUsersApi } from './apis/searchusers.api'
import parse from 'html-react-parser'
import Map from '../Shared/Map'
import Farmer from '../Shared/Farmer'
import FarmerModal from '../Shared/FarmerModal'
import AddToPool from '../Shared/AddToPool'
import Pagination from '../Shared/Pagination'
import styles from './styles/searchusers.module.scss'

const SearchUsers = ({ userInfo, setNewMember }) => {
    const [term, setTerm] = useState('')
    const [searchResultsTerm, setSearchResultsTerm] = useState('')
    const [users, setUsers] = useState([])
    const dispatch = useDispatch()
    const submit = async () => {
        setSearchResultsTerm('')
        dispatch(setLoader(true))
        const response = await searchUsersApi(term).then(response => {
            dispatch(setLoader(false))
            return response
        })
        if (response && response.status === 200) {
            setSearchResultsTerm(term)
            setUsers(response.value || [])
            setTerm('')
        } else {
            setSearchResultsTerm('')
        }
    }
    //modal functionality
    const [showModal, setShowModal] = useState(false)
    const [selectedID, setSelectedID] = useState("")
    const [modalFarmer, setModalFarmer] = useState({ })
    const closeModal = () => {
        setShowModal(false)
        setModalFarmer({ })
        setSelectedID("")
    }
    useEffect(() => {
        const farmerDetails = users.filter(farmerDetails => farmerDetails.user_id === selectedID)
        setModalFarmer(farmerDetails[0])
    }, [selectedID, users]);
    //pagination
    const [usersPerPage, setUsersPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const usersRef = useRef(null)
    const handleChange = (event, value) => {
        setPage(value)
        page !== value && usersRef.current.scrollIntoView({
            behavior: 'smooth'
        })
    }
    //Get current users
    const indexOfLastMarker = page * usersPerPage;
    const indexOfFirstMarker = indexOfLastMarker - usersPerPage;
    const currentMarkers = users.slice(indexOfFirstMarker, indexOfLastMarker)

    useEffect(() => {
        setCount(Math.ceil(users.length / usersPerPage))
    }, [users, usersPerPage])
    return (
        <div className={styles.container}>
            <span onClick={() => setNewMember(false)} className={styles.back}>Back</span>
            <h3>Search users to add</h3>
            <div className={styles.inputContainer} onKeyDown={({ key }) => {
                key === "Enter" && submit()
            }}>
                <input
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}
                    name="user" />
                <button onClick={submit}>Go</button>
            </div>
            <div className={styles.middle}>
                {searchResultsTerm && <span className={styles.term}>Showing Search Results for: <span>{searchResultsTerm}</span></span>}
                {users.length !== 0 && searchResultsTerm && <AddToPool farmerDetails={users} all />}
            </div>
            <div className={styles.bottom}>
                {users.length !== 0 && searchResultsTerm ?
                    <div ref={usersRef} className={styles.farmlands}>
                        <div className={styles.heading}>
                            <h4 className={styles.id}>Agro-ID</h4>
                            <h4 className={styles.farmer}>Basic Info</h4>
                            <h4 className={styles.location}>Location</h4>
                            <h4 className={styles.capacity}>Capacity</h4>
                            {/* <h4>Size</h4> */}
                            <h4 className={styles.farming_type}>Farming Type</h4>
                        </div>
                        {
                            users.length !== 0 && currentMarkers.map((marker, index) => (
                                <Farmer key={index} marker={marker} setShowModal={setShowModal} setSelectedID={setSelectedID} />
                            ))
                        }
                    </div> :
                    <span className={styles.noResults}>
                        {searchResultsTerm && parse(`No results available for <span>${searchResultsTerm}</span>`)}
                    </span>
                }

                {users.length !== 0 && searchResultsTerm &&
                    <div className={styles.pagination}>
                        <Pagination count={count} page={page} handleChange={handleChange} />
                    </div>
                }
                {(users.length !== 0 && searchResultsTerm) && <div className={styles.map}>
                    <Map markers={users} />
                </div>}
            </div>
            {(showModal && modalFarmer) && <FarmerModal modalFarmer={modalFarmer} setShowModal={setShowModal} closeModal={closeModal} />}
        </div>
    )
}

export default SearchUsers
