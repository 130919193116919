import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { routes } from '../../global/utils/routeNames'
import { setShowModal } from '../../state/slices/modal.slice'
import styles from './styles/signupbottom.module.scss'

const SignUpBottom = ({ formik }) => {
    const dispatch = useDispatch()
    return (
        <div className={styles.container}>
            <div className={styles.privacyncheckbox}>
                <input id="terms-conditions" required className={styles.checkbox} type="checkbox" name="terms-conditions" />
                <label htmlFor="terms-conditions" className={styles.privacypolicy}>
                    I agree to Agroverified's <Link onClick={() => dispatch(setShowModal(false))} to={routes.terms_of_use}>Terms of Use</Link> and <span>Privacy Policy</span></label>
            </div>
            <button disabled={formik.isSubmitting} type="submit">
                Create Account
            </button>
        </div>
    )
}

export default SignUpBottom
