export const heroImages = [
    'https://res.cloudinary.com/agroverifiednig/image/upload/v1623311786/website/home/hero1_rgdt3r.png',
    'https://res.cloudinary.com/agroverifiednig/image/upload/v1623311786/website/home/hero2_jtkmn1.png',
    'https://res.cloudinary.com/agroverifiednig/image/upload/v1623169214/website/home/hero3_x0s4hh.png',
    'https://res.cloudinary.com/agroverifiednig/image/upload/v1623169220/website/home/hero4_yt2bem.png',
    'https://res.cloudinary.com/agroverifiednig/image/upload/v1623169210/website/home/hero5_rpuild.png',
    'https://res.cloudinary.com/agroverifiednig/image/upload/v1623169207/website/home/hero6_ga5cfz.png',
    'https://res.cloudinary.com/agroverifiednig/image/upload/v1623169197/website/home/hero7_x9f8xf.jpg',
    'https://res.cloudinary.com/agroverifiednig/image/upload/v1623169203/website/home/hero8_agcmz3.jpg',
    'https://res.cloudinary.com/agroverifiednig/image/upload/v1623169213/website/home/hero9_sl8zih.jpg'
]