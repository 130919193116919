import { useRef } from 'react'
import { createPortal } from 'react-dom'
import VerificationModalContent from './VerificationModalContent'
import styles from './styles/verificationmodal.module.scss'

const VerificationModal = ({ closeModal, farmer }) => {
    const modalRef = useRef()

    const closeModalTarget = (e) => {
        if (modalRef.current === e.target) {
            closeModal()
        }
    }
    return createPortal(
        <div ref={modalRef} onClick={closeModalTarget} className={styles.container}>
            <VerificationModalContent farmer={farmer} closeModal={closeModal} />
        </div>
        , document.getElementById('portal'))
}

export default VerificationModal
