export const team = [
    {
        id: 1,
        name: 'Helen Ese Emore',
        photo: 'https://res.cloudinary.com/agroverifiednig/image/upload/v1629534267/website/about-us/Helen_n9hpqd.jpg',
        role: 'Co-Founder, CEO',
        biography: 'Helen is currently a Phd candidate at International School of Management, Paris. M.A Business Management, Northumbria University UK and  Master of Public and International Affairs from University of Lagos.<br/><br/>She is passionate about supporting Youth & Women in the Agribusiness Ecosystem and Sustainable Technology Enabled Ventures.Actively working for the   growth and sustainability of MSMEs.She has trained over 10, 000 + MSMEs, coached and mentored 100 + to grow into multi million Naira ventures.<br/><br/>She is an adjunct faculty member at the School of Media and Communication and Enterprise Development Centre - EDC, Pan - Atlantic University, Lagos.As a Business advisor, she is an Expert in the Residence programme at EDC, working with entrepreneurs in agribusiness, manufacturing, tech, fashion and creative industries to grow their ventures.<br/><br/>She is a Certified international facilitator(International Finance Corporation(IFC), a Business Edge Trainer and a Learning and Performance Institute(LPI) certified trainer.She is a an Adjunct Faculty at the Pan Atlantic University School of Media and Communication.<br/><br/>She is an experienced international trainer in corporate governance, business and organizational development and has facilitated international and local corporate organizations in the public and private sectors.<br/><br/>West African Region Consultant to the University of St Gallen Switzerland(Executive School of Management, Technology and Law Custom Programs).She worked with them on a One year certificate Leadership programme  for the Economic Commission for West African States Leadership training programme for 100 + Directors and senior executives from the 16 ECOWAS countries.This included an immersion and Leadership and Executive Management(President, Vice - President and all Commissioners and Heads of Agencies).'
    }, {
        id: 2,
        name: 'Richard B. Ogundele',
        photo: 'https://res.cloudinary.com/agroverifiednig/image/upload/v1629534269/website/about-us/Richard-Ogundele_ji2mmf.jpg',
        role: 'Co-Founder, Executive Director Strategy and Business Development',
        biography: 'Richard is a Value Chain/Market Systems/Private Sector Development expert with extensive project management experience. His experience covers the fresh perishable produce sector amongst other value chains in Nigeria for the past 20+ years. His value chain development experience covers shea butter, potatoes, cassava, rice, tomatoes, poultry, sesame, cashew, soya beans, maize; large and small ruminants.<br/><br/>Richard pioneered the development and promotion for the adoption of Good Handling Practices(GHP), use of Returnable Plastic Crates(RPC), The Crate Rental Business Model(CRBM), Organization of Aggregation Centers(ACS), and application of Tomato Colour Code(TCC) in Nigeria via the DFID/ UKAID Funded GEMS4 Wholesale Retail Market System Development Project.He currently runs JMSF Agribusiness Nigeria, an Advisory Services Firm(<a href="https://www.jmsfagribusiness.com" target="_blank" rel="noopener noreferrer">www.jmsfagribusiness.com</a>) as its MD / CEO and based in Abuja FCT Nigeria.<br/><br/>Richard has an extensive local, regional and international network, well - travelled, and is passionate about entrepreneurship in the agribusiness space to stimulate economic growth, value addition, and job creation.'
    }, {
        id: 3,
        name: 'Victoria Madedor',
        photo: 'https://res.cloudinary.com/agroverifiednig/image/upload/v1629534267/website/about-us/Victoria-madedor_gjb2rv.jpg',
        role: 'Co-Founder, Board Adviser - Chairman',
        biography: 'She holds B.Agric in Agricultural Economics from the University of Benin, Edo State. Masters in Business Administration (MBA) and currently pursuing Masters in Marketing and Innovation (MA) at the Metropolitan School Of Business UK.<br/><br/>An experienced Business Development Consultant with a focus on the Agricultural Sector and currently Group Head Business Development at BOI Investment and Trust Company, a subsidiary of the Bank of Industry Nigeria, She has volunteered as a mentor for Google Accelerator LaunchPad(2017), Lagos State Government Ready Set Work Program(2017), International Business Accelerator(2018).<br/><br/>Victoria has worked as a resource person for several organizations including Lagos Business School, Lagos State Government, Edo State Government, MyFarmbaseAfrica.Invest Africa Magazine, JMSF Agribusiness on World Bank Project, and Global Alliance for Improved Nutrition.<br/><br/>She sits as board adviser to several Agribusiness organizations and the initiator of Sluminclusion focused on Slum Development through Agribusiness and Co- Initiator of Support4Africansmes with a focus on telling the African Farmers\' Stories.'
    }
]