import { useEffect } from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { dashboardAsideLinks } from './utils/dashboardasidelinks'
import styles from './styles/dashboardaside.module.scss'

const DashboardAside = ({ userInfo }) => {
    const [dashboardItems, setDashboardItems] = useState([])

    useEffect(() => {
        const userDashboard = userInfo.institution_id && !userInfo.is_deleted && !userInfo.is_admin ?
            dashboardAsideLinks.filter(link => link.userType === 'all' || (link.userType.includes('institution') && !link.userType.includes('farmer'))) :
            userInfo.institution_id && !userInfo.is_deleted ?
                dashboardAsideLinks.filter(link => link.userType === 'all' || link.userType.includes('institution'))
                : dashboardAsideLinks.filter(link => link.userType === 'all' || link.userType.includes(userInfo.user_type))
        setDashboardItems(userDashboard)
    }, [userInfo])

    return (
        <div className={styles.container}>
            <div className={styles.menu}>
                {dashboardItems && dashboardItems.map((link) =>
                    <NavLink activeClassName={styles.active} to={link.slug} key={link.id}>
                        <p className={styles.link}>
                            <span className={styles.icon}>{link.icon}</span>
                            <span className={styles.text}>{link.name}</span>
                        </p>
                    </NavLink>
                )}
            </div>
        </div>
    )
}

export default DashboardAside
