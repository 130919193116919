import { useRef, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import Headings from '../Shared/Headings'
import { routes } from '../../global/utils/routeNames'
import styles from './styles/connect.module.scss'
import { connectGsap } from './animations'

const Connect = () => {
    const connectRef = useRef(null)
    const connectHeadingRef = useRef(null)
    const connectTextRef = useRef(null)
    const connectPictureRef = useRef(null)
    useLayoutEffect(() => {
        connectGsap(connectRef, connectHeadingRef, connectTextRef, connectPictureRef)
    }, [])
    return (
        <div ref={connectRef} className={styles.container}>
            <Headings headingRef={connectHeadingRef} heading="connect" />
            <div>
                <section ref={connectTextRef}>
                    <h3>Do you want to connect with
                        a verified Agropreneur?</h3>
                    <p>Our Unique Identification Number gives you instant access to a wide variety of agroprenuers’ profiles in seconds:</p>
                    <ul>
                        <li>AgroVerified covers over 200,000 verified farmers, aggregators, and producers.</li>
                        <li>Acquire and onboard authenticated farmers, aggregators, and agricultural producers with confidence.</li>
                        <li>Source verified suppliers across Nigeria.</li>
                        <li>Reduce your KYC completion time.</li>
                        <li>We offer the widest Agro-focused KYC reach in Nigeria.</li>
                    </ul>
                    {/* <Link to={routes.market}><button>Connect Now</button></Link> */}
                    <button>Coming Soon</button>
                </section>
                <div>
                    <picture ref={connectPictureRef} className={styles.sitting}>
                        <img loading="lazy" src="https://res.cloudinary.com/agroverifiednig/image/upload/v1623311783/website/home/connect-sitting_niw7fh.png" alt="farmer" />
                    </picture>
                </div>
            </div>
        </div>
    )
}

export default Connect
