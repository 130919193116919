import { Formik, Form } from 'formik'
import { signupApi } from './apis/signup.api'
import SignUpBottom from './SignUpBottom'
import TextInput from './TextInput'
import PasswordInput from './PasswordInput'
import styles from './styles/signup.module.scss'
import { endUserInitialValues } from './utils/forminitialvalues'
import { endUserValidationSchema } from './utils/formvalidation'
import { useDispatch } from 'react-redux'
import { setLoader } from '../../state/slices/loader.slice'

const EndUserForm = ({ userType, setVerify }) => {
    const dispatch = useDispatch()
    return (
        <div className={styles.container}>
            <Formik
                initialValues={endUserInitialValues}
                validationSchema={endUserValidationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    dispatch(setLoader(true))
                    const response = await signupApi(values, userType).then(response => {
                        dispatch(setLoader(false))
                        setSubmitting(false)
                        return response
                    })
                    if (response.status === 200) {
                        resetForm()
                        setVerify(true)
                    }
                }}
            >
                {formik =>
                    <Form className={styles.form}>
                        <div className={styles.doubleDiv}>
                            <TextInput autoFocus type='text' label='First Name' name='first_name' />
                            <TextInput type='text' label='Last Name' name='last_name' />
                        </div>
                        <TextInput type='tel' label='Phone Number' name='phone_number' placeholder="enter your phone number +(country code)" />
                        <div className={styles.doubleDiv}>
                            <TextInput type='text' label='Email Address' name='email' />
                            <PasswordInput label='Password' name='password' />
                        </div>
                        <SignUpBottom formik={formik} />
                    </Form>
                }
            </Formik>
        </div>
    )
}

export default EndUserForm
