import { useField } from 'formik';
import { BiShow, BiHide } from 'react-icons/bi';
import { useState } from 'react';
import styles from './styles/input.module.scss';

const PasswordInput = ({ label, ...props }) => {
    const [field, meta] = useField(props)
    const [showPassword, setShowPassword] = useState(false)
    const showHide = () => {
        setShowPassword(prev => !prev)
    }
    return (
        <div className={styles.container}>
            <label className={meta.touched && meta.error ? styles.labelError : ''} htmlFor={props.id}>{label}</label>
            <div className={styles.passwordField}>
                <input
                    className={meta.touched && meta.error ? styles.inputError : ''}
                    {...field}
                    {...props}
                    type={!showPassword ? 'password' : 'text'} />
                <button type="button" onClick={showHide}>{!showPassword ? <BiShow /> : <BiHide />}</button>
            </div>
            {
                meta.touched && meta.error ? (
                    <div className={styles.error}>{meta.error}</div>
                )
                    : null
            }
        </div>
    )
}

export default PasswordInput