import { useState } from 'react'
import { Formik, Form } from 'formik'
import { cropValidationSchema } from '../utils/formvalidation'
import { cropInitialValues } from '../utils/forminitialvalues'
import CropsForm from './CropsForm'
import styles from '../styles/updateprofile.module.scss'
import { useDispatch } from 'react-redux'
import { setLoader } from '../../../state/slices/loader.slice'
import { productsApi } from '../api/products.api'

const Crops = ({ userInfo, setTab }) => {
    const dispatch = useDispatch()
    const [cropImage, setCropImage] = useState([])
    return (
        <div className={styles.container}>
            {userInfo._id &&
                <Formik onSubmit={async (values, { resetForm, setSubmitting }) => {
                    dispatch(setLoader(true))
                    const response = await productsApi('crop', values, cropImage).then(response => {
                        dispatch(setLoader(false))
                        setSubmitting(false)
                        return response
                    })
                    if (response.status === 200) {
                        resetForm()
                        // setTab(0)
                    }
                }}
                    initialValues={cropInitialValues}
                    validationSchema={cropValidationSchema}>

                    {formik =>
                        <Form encType="multipart/form-data" id="cropForm">
                            <CropsForm setCropImage={setCropImage} formik={formik} />
                        </Form>
                    }

                </Formik>}
        </div>
    )
}

export default Crops
