import Meta from "../components/Meta/Meta"
import Main from "../components/Page404/Main"

const Page404 = () => {
    const meta = {
        title: "Page Not Found",
        keywords: "",
        description: ""
    }
    return (
        <>
            <Meta {...meta} />
            <Main />
        </>
    )
}

export default Page404
