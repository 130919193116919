import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { setLoader } from "../../state/slices/loader.slice"
import { forgotPasswordApi } from './apis/forgotpassword.api'
import styles from './styles/loginform.module.scss'
import TextInput from './TextInput'
import { forgotInitialValues } from './utils/forminitialvalues'
import { forgotValidationSchema } from './utils/formvalidation'

const ForgotPasswordForm = ({ setAuthState }) => {
    const dispatch = useDispatch()

    return (
        <Formik
            initialValues={forgotInitialValues}
            validationSchema={forgotValidationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
                dispatch(setLoader(true))
                const response = await forgotPasswordApi({ phone_number: values.phone_number }).then(response => {
                    dispatch(setLoader(false))
                    setSubmitting(false)
                    return response
                })
                if (response && response.status === 200) {
                    resetForm()
                    setAuthState('reset')
                }
            }}
        >
            {formik =>
                <Form className={`${styles.container}`}>
                    <TextInput autoFocus type='tel' name='phone_number'
                        placeholder='enter your phone number +(country code)' />
                    <button type="button" onClick={() => setAuthState('login')} className={styles.forgotPassword}>Remember Password? Sign In!</button>
                    <button className={styles.submit} disabled={formik.isSubmitting} type="submit">
                        Submit
                    </button>
                </Form>
            }
        </Formik>
    )
}

export default ForgotPasswordForm
