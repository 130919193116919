import Members from './Members'
import AddMember from './AddMember'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Confirm from './Confirm'

const Main = () => {
    const [employeeId, setEmployeeId] = useState('')
    const [newMember, setNewMember] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalAction, setModalAction] = useState('')
    const userInfo = useSelector(state => state.userInfo.value)

    return (
        <>
            {!newMember ?
                !userInfo.is_deleted && <Members userInfo={userInfo} setEmployeeId={setEmployeeId} setNewMember={setNewMember} setShowModal={setShowModal} setModalAction={setModalAction} /> :
                <AddMember userInfo={userInfo} setNewMember={setNewMember} />
            }
            {showModal && <Confirm modalAction={modalAction} employeeId={employeeId} setEmployeeId={setEmployeeId} userInfo={userInfo} setShowModal={setShowModal} />}
        </>
    )
}

export default Main
