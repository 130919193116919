import PoolMembers from './PoolMembers'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import queryString from 'query-string'
import Confirm from './Confirm'
import SearchUsers from './SearchUsers'

const PoolMembersContainer = () => {
    const [currentUser, setCurrentUser] = useState({})
    const [currentId, setCurrentId] = useState("")
    const [pool, setPool] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [newMember, setNewMember] = useState(false)
    const location = useLocation()
    const userInfo = useSelector(state => state.userInfo.value)
    const institutionPools = useSelector(state => state.institutionPools.value)
    const currentPoolId = queryString.parse(location.search).pool

    useEffect(() => {
        userInfo._id && setCurrentUser(userInfo)
    }, [userInfo, userInfo.updatedAt])

    useEffect(() => {
        const currentPool = institutionPools.filter(pool => pool._id === currentPoolId)
        setPool(currentPool[0] || {})
    }, [institutionPools, currentPoolId])


    return (
        <>
            {!newMember ?
                pool._id && <PoolMembers pool={pool} userInfo={currentUser} setNewMember={setNewMember} setCurrentId={setCurrentId} setConfirmModal={setShowModal} /> :
                <SearchUsers userInfo={userInfo} setNewMember={setNewMember} />
            }
            {showModal && <Confirm setShowModal={setShowModal} currentId={currentId} setCurrentId={setCurrentId} />}
        </>
    )
}

export default PoolMembersContainer
