import styles from './styles/headings.module.scss'

const Headings = ({ heading, headingRef }) => {
    return (
        <div ref={headingRef ? headingRef : null} className={styles.container}>
            <h2> {heading} </h2>
        </div>
    )
}

export default Headings
