import { useRef } from 'react'
import { createPortal } from 'react-dom'
import FarmerModalContent from './FarmerModalContent'
import styles from './styles/farmermodal.module.scss'

const FarmerModal = ({ closeModal, modalFarmer, remove, poolID }) => {
    const modalRef = useRef()

    const closeModalTarget = (e) => {
        if (modalRef.current === e.target) {
            closeModal()
        }
    }
    return createPortal(
        <div ref={modalRef} onClick={closeModalTarget} className={styles.container}>
            <FarmerModalContent poolID={poolID} modalFarmer={modalFarmer} remove={remove} closeModal={closeModal} />
        </div>
        , document.getElementById('portal'))
}

export default FarmerModal
