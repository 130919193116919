import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'
import { setPoolsReload } from '../../state/slices/pools.slice'
import { setLoader } from '../../state/slices/loader.slice'
import styles from './styles/confirm.module.scss'
import { deletePoolApi } from './apis/deletepool.api'
import { useHistory } from 'react-router-dom'
import { routes } from '../../global/utils/routeNames'

const Confirm = ({ setShowModal, currentId, setCurrentId }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const onDelete = async () => {
        dispatch(setLoader(true))
        await deletePoolApi(currentId).then(response => {
            setCurrentId('')
            setShowModal(false)
            dispatch(setPoolsReload(true))
            dispatch(setLoader(false))
            history.push(routes.institution_pools)
            return response
        })
    }
    return createPortal(
        <div className={styles.container}>
            <div className={styles.box}>
                <h4>Confirm Deletion</h4>
                <p>Are you sure you want to delete this pool?</p>
                <div className={styles.buttons}>
                    <button onClick={() => setShowModal(false)} className={styles.cancel}>Cancel</button>
                    <button onClick={onDelete} className={styles.act}>Delete</button>
                </div>
            </div>
        </div>
        , document.getElementById('portal'))
}

export default Confirm
