import { GiCheckMark } from 'react-icons/gi'
import styles from './styles/text.module.scss'

const Text = () => {
    return (
        <div className={styles.container}>
            <div className={styles.signintext}>
                <h2 className={styles.dontgetscammed}>Don't get scammed! Join the only integrity ecosystem for agribusiness in Nigeria.</h2>
                <ul>
                    <li><span><GiCheckMark /></span>Get verified farmers information.</li>
                    <li><span><GiCheckMark /></span>Get accurate info about farmlands and locations, sizes, crop types etc.</li>
                    <li><span><GiCheckMark /></span>Trade with transparency.</li>
                    <li><span><GiCheckMark /></span>Get access to financial support.</li>
                </ul>
            </div>
        </div>
    )
}

export default Text
