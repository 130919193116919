import { useRef, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom';
import { routes } from '../../global/utils/routeNames';
import Headings from '../Shared/Headings'
import Story from '../Shared/Story';
import styles from './styles/stories.module.scss';
import { storiesGsap } from './animations'

const Stories = () => {
    const storiesRef = useRef(null)
    const storiesHeadingRef = useRef(null)
    const storiesParagraphRef = useRef(null)
    const storyRef = useRef(null)
    useLayoutEffect(() => {
        storiesGsap(storiesRef, storiesHeadingRef, storiesParagraphRef, storyRef)
    }, [])
    return (
        <div ref={storiesRef} className={styles.container}>
            <Headings headingRef={storiesHeadingRef} heading="latest stories" />
            <p ref={storiesParagraphRef}>Connect, Share and Learn.</p>
            {/* <Link to={routes.new_story}><button>Start a new story</button></Link> */}
            <div>
                <div className={styles.story}>
                    <div ref={storyRef}>
                        <p>Coming Soon!</p>
                    </div>
                </div>
                {/* <div className={styles.advertisement}></div> */}
            </div>
        </div>
    )
}

export default Stories
