import { useLayoutEffect } from 'react'
import { useRef } from 'react'
import { statementsGsap } from './animations'
import styles from './styles/statements.module.scss'

const Statements = () => {
    const statementsRef = useRef(null)
    const statementRef = useRef(null)
    useLayoutEffect(() => {
        statementsGsap(statementsRef, statementRef)
    }, [])
    return (
        <div ref={statementsRef} className={styles.container}>
            <section id="statement" ref={statementRef} className={styles.mission}>
                <h4>Mission</h4>
                <p>To create a profitable and transparent integrity ecosystem for all agricultural players by providing access to verified information with ease.
                </p>
            </section>
            <section id="statement" ref={statementRef} className={styles.vision}>
                <h4>Vision</h4>
                <p>Our vision is to ensure that our integrity ecosystem provides access to a wide range of verified agricultural players and products to carry out transparent trades across Africa.</p>
            </section>
        </div>
    )
}

export default Statements
