import TextInput from './TextInput'
import SelectInput from './SelectInput'
import styles from '../styles/inputscontainer.module.scss'
import UploadInput from './UploadInput'

const FarmlandForm = ({ formik, setUrls, setFarmlandImage }) => {
    return (
        <>
            <h3>Farmland</h3>
            <div className={styles.container}>
                <TextInput label="size of farmland" name="size" type="text" placeholder="" />
                <TextInput label="capacity of farmland" name="capacity" type="text" placeholder="" />
                <div className={styles.customInput}>
                    <label htmlFor="">type of farming</label>
                    <SelectInput formik={formik} field="type_of_farming"
                        defaultValue={formik.values.type_of_farming}
                        values={["Crops Farming", "Livestock Farming", "Crops and Livestock Farming"]} />
                </div>
                <div className={styles.customInput}>
                    <label htmlFor="">Do you own this farmland?*</label>
                    <SelectInput formik={formik} field="ownership" defaultValue={formik.values.ownership} values={["yes", "no"]} />
                </div>
                {/* if farmland belongs to farmer */}
                {formik.values.ownership === "yes" &&
                    <>
                        <TextInput label="If farmland belongs to you, what type of document do you have?"
                            name="document_type_label" type="text" placeholder="" />
                        <TextInput label="How many years have you owned the farm?"
                            name="duration_of_ownership" type="text" placeholder="0-3 years" />
                    </>
                }
                {/* if farmland doesn't belong to farmer */}
                {formik.values.ownership === "no" &&
                    <>
                        <TextInput label="What type of document do you do you have to show permission to farm on the land?"
                            name="document_type_label" type="text" placeholder="" />
                    </>
                }
                {formik.values.ownership && <TextInput label="How many years have you been on land?"
                    name="duration_on_farmland" type="text" placeholder="0-3 years" />}
                {/* upload documents */}
                {formik.values.ownership && <div className={styles.customInput}>
                    <label htmlFor="">Upload Documents That Prove Ownership or Rental</label>
                    <span>Pictures of documents you have as proof</span>
                    <UploadInput formik={formik}
                        action="/farmland/farmland-documents"
                        id="farmland_document"
                        multiple
                        setUrls={setUrls}
                        field="farmland_document"
                        defaultValue={formik.values.farmland_document} />
                </div>}
                {/* upload farmland pictures */}
                {formik.values.ownership && <div className={styles.customInput}>
                    <label htmlFor="">Pictures of Farmland</label>
                    <span>Snap pictures of the four corners of your farm using a smartphone with the gps location turned on and upload. Turn on your phone location and allow camera to use location. See how to do that if you are using android: <a href="https://support.google.com/android/answer/9431959?hl=en" target="_blank" rel="noopener noreferrer">https://support.google.com/android/answer/9431959?hl=en</a> or if you are using iphone: <a href="https://support.apple.com/en-us/HT207092" target="_blank" rel="noopener noreferrer">https://support.apple.com/en-us/HT207092</a>
                    </span>
                    <UploadInput formik={formik} field="farmland_image"
                        id="farmland_image"
                        multiple
                        setImage={setFarmlandImage}
                        defaultValue={formik.values.farmland_image}
                        maxCount={1} />
                </div>}
            </div>
            <div className={styles.buttons}>
                <button className={styles.submit} type="submit">Submit</button>
            </div>
        </>
    )
}

export default FarmlandForm

