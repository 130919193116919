import DashboardAside from '../Shared/DashboardAside'
import { useDispatch, useSelector } from 'react-redux'
import { setInstitutionPools, setPoolsReload } from '../../state/slices/pools.slice'
import { getAllPoolsApi } from '../InstitutionPools/apis/getallpools.api'
import { useEffect } from 'react'
import styles from './styles/accountlayout.module.scss'

const AccountLayout = ({ children, userInfo }) => {
    const dispatch = useDispatch()

    //institution pools state
    const institutionPools = useSelector(state => state.institutionPools.value)
    const PoolsReload = useSelector(state => state.institutionPools.reload)

    useEffect(() => {
        dispatch(setInstitutionPools([]))
        dispatch(setPoolsReload(true))
    }, [dispatch])

    useEffect(() => {
        const getPools = async () => {
            const response = await getAllPoolsApi(userInfo.institution_id).then(response => response)
            if (response && response.status === 200) {
                dispatch(setInstitutionPools(response.value || []))
                dispatch(setPoolsReload(false))
            }
        }
        (userInfo.institution_id && !userInfo.is_deleted && PoolsReload) && getPools()
    }, [institutionPools.length, PoolsReload, userInfo, userInfo._id, dispatch])
    return (
        <div className={styles.container}>
            <DashboardAside userInfo={userInfo} />
            {children}
        </div>
    )
}

export default AccountLayout
