import { useState } from 'react'
import { Formik, Form } from 'formik'
import { basicValidationSchema } from '../utils/formvalidation'
import { basicInitialValues } from '../utils/forminitialvalues'
import BasicInformationForm from './BasicInformationForm'
import styles from '../styles/updateprofile.module.scss'
import { personalApi } from '../api/personal.api'
import { setLoader } from '../../../state/slices/loader.slice'
import { useDispatch } from 'react-redux'
import { setUserInfo } from '../../../state/slices/userinfo.slice'

const BasicInformation = ({ userInfo, setTab }) => {
    const dispatch = useDispatch()
    const [displayPicture, setDisplayPicture] = useState([])
    return (
        <div className={styles.container}>
            {userInfo._id &&
                <Formik onSubmit={async (values, { resetForm, setSubmitting }) => {
                    dispatch(setLoader(true))
                    const response = await personalApi(values, userInfo._id, displayPicture).then(response => {
                        dispatch(setLoader(false))
                        setSubmitting(false)
                        return response
                    })
                    if (response.status === 200) {
                        dispatch(setUserInfo(response.value))
                        resetForm()
                        setTab(0)
                    }
                }}
                    initialValues={userInfo || basicInitialValues}
                    validationSchema={basicValidationSchema}>

                    {formik =>
                        <Form id="personalForm" encType="multipart/form-data">
                            <BasicInformationForm setDisplayPicture={setDisplayPicture} formik={formik} />
                        </Form>
                    }

                </Formik>}
        </div>
    )
}

export default BasicInformation
