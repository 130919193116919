import http from '../../../http-common'
import Cookies from "js-cookie";
import swal from "sweetalert";

export const personalApi = async (values, userId, displayPicture) => {
    const currentUser = Cookies.get('auth') || "{}"
    const token = JSON.parse(currentUser).data?.token.token
    const form = document.getElementById('personalForm')
    const formData = new FormData(form)
    formData.append('gender', values.gender)
    formData.append('date_of_birth', values.date_of_birth)
    displayPicture[0] && formData.append('photo_url', displayPicture[0])
    const request = {
        method: 'PATCH',
        url: "/user/update/" + userId,
        headers: {
            "Content-Type": undefined,
            authorization: 'Bearer ' + token
        },
        data: formData
    };
    try {
        let response = await http({
            ...request
        }).catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log(err.response)
                swal({
                    icon: 'error',
                    text: err.response.data.error || err.response.data.message,
                    timer: 4000,
                    button: false,
                })
            } else if (err.request) {
                // client never received a response, or request never left
                console.log(err.request)
            } else {
                // anything else
                console.log(err)
            }
        })
        if (response.status === 200) {
            const data = response.data
            swal({
                icon: 'success',
                text: data.message,
                timer: 4000,
                button: false,
            });
            return response = {
                value: data,
                status: 200
            }
        }
    } catch (e) {
        console.log(e)
    }
}