import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { NavLink, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { CgMenuRight } from 'react-icons/cg'
import { IoMdClose } from 'react-icons/io'
import { setAuth } from "../../state/slices/auth.slice";
import { setShowModal, setStatus } from '../../state/slices/modal.slice'
import { setUserInfo } from "../../state/slices/userinfo.slice";
import AvatarMenu from "./AvatarMenu";
import MobileMenu from "./MobileMenu";
// import Services from './Services'
import Cookies from 'js-cookie'
import styles from './styles/navbar.module.scss';
import { routes } from "../../global/utils/routeNames";
import { useLocation } from "react-router";
import { navbarGsap } from './animations'

const Navbar = () => {
    const dispatch = useDispatch()
    const location = useLocation()

    //setNavbar
    const [navbar, setNavbar] = useState(false)
    const changeBackground = () => {
        if (location.pathname !== routes.auth) {
            window.scrollY >= 80 ? setNavbar(true) : setNavbar(false)
        } else {
            setNavbar(true)
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', changeBackground)
        changeBackground()
        return () => {
            window.removeEventListener('scroll', changeBackground)
        };
    });

    //mobile menu
    const [showMenu, setShowMenu] = useState(false)
    const controlMenu = () => setShowMenu(prev => !prev)

    // login/signup state
    const openModal = (status) => {
        dispatch(setShowModal(true));
        dispatch(setStatus(status));
    }

    //close on external click
    const headerRef = useRef()
    const handleClick = (e) => {

        if (headerRef.current === e.target && showMenu) {
            setShowMenu(false)
        }
    }
    // useLayoutEffect(() => {
    //     navbarGsap(headerRef)
    // }, [])

    //log out 
    const auth = useSelector(state => state.auth.value)
    const logOut = () => {
        Cookies.remove('auth')
        dispatch(setAuth("{}"))
        dispatch(setUserInfo({}))
    }

    //get user's data
    const userInfo = useSelector(state => state.userInfo.value)
    const [currentUser, setcurrentUser] = useState({})
    useEffect(() => {
        setcurrentUser(userInfo)
    }, [userInfo])
    return (
        <>
            <header ref={headerRef} onClick={handleClick} className={!navbar ? styles.container : `${styles.container} ${styles.active}`}>
                <Link to={routes.home}>
                    {/* <h1><span>AgroV</span>erified</h1> */}
                    <img src="https://res.cloudinary.com/agroverifiednig/image/upload/v1625915203/website/logos/logo_full_color.png" alt="logo" />
                </Link>
                <nav>
                    <NavLink exact to={routes.home} activeClassName={styles.active}><span>Home</span></NavLink>
                    <NavLink to={routes.about} activeClassName={styles.active}><span>About</span></NavLink>
                    {auth.status === "success" && <NavLink to={routes.profile} activeClassName={styles.active}><span>Profile</span></NavLink>}
                    {/* <Services /> */}
                    {/* <NavLink to={routes.community} activeClassName={styles.active}><span>Community</span></NavLink> */}
                    <NavLink to={routes.contact} activeClassName={styles.active}><span>Contact</span></NavLink>
                </nav>
                <div className={styles.buttons}>
                    {auth.status === "success" ?
                        <AvatarMenu logOut={logOut} userInfo={currentUser} />
                        :
                        ''}
                    {auth.status !== "success" ? <>
                        <NavLink to={routes.auth} className={styles.login}>Sign In</NavLink>
                        {/* <button onClick={() => openModal("signup")} className={styles.signup}>Sign Up</button> */}
                    </> :
                        <button onClick={logOut} className={styles.signup}>Log Out</button>
                    }
                </div>
                <div className={styles.mobileMenu}>
                    <span className={styles.icon} onClick={controlMenu}>{!showMenu ? <CgMenuRight /> : <IoMdClose />}</span>
                    <MobileMenu openModal={openModal} auth={auth} logOut={logOut} showMenu={showMenu} setShowMenu={setShowMenu} />
                </div>
            </header>
        </>
    )
}

export default Navbar
