import { Formik, Form } from 'formik'
import TextInput from "./TextInput";
import PasswordInput from "./PasswordInput";
import { setLoader } from "../../state/slices/loader.slice"
import { useDispatch } from "react-redux";
import { resetInitialValues } from "./utils/forminitialvalues";
import { resetValidationSchema } from "./utils/formvalidation";
import styles from "./styles/loginform.module.scss"
import { resetPasswordApi } from "./apis/resetpassword.api";

const ResetPasswordForm = ({ setAuthState }) => {
    const dispatch = useDispatch()
    return (
        <Formik
            initialValues={resetInitialValues}
            validationSchema={resetValidationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
                dispatch(setLoader(true))
                const response = await resetPasswordApi(values).then(response => {
                    dispatch(setLoader(false))
                    setSubmitting(false)
                    return response
                })
                if (response && response.status === 200) {
                    resetForm()
                    setAuthState('login')
                }
            }}
        >
            {formik =>
                <Form className={`${styles.container} ${styles.reset}`}>
                    <TextInput autoFocus type='text' name='token'
                        placeholder='enter token sent to your phone number' maxLength="6" />
                    <PasswordInput name='password' placeholder='enter new password' />
                    <button type="button" onClick={() => setAuthState('login')} className={styles.forgotPassword}>Remember Password? Sign In!</button>
                    <button className={styles.submit} disabled={formik.isSubmitting} type="submit">
                        Reset Password
                    </button>
                </Form>
            }
        </Formik>

    )
}

export default ResetPasswordForm
