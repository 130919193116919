import { useState } from 'react'
import EndUserForm from './EndUserForm'
import FarmerForm from './Farmer'
import InstitutionForm from './Institution'
import UserType from './UserType'
import Verification from './Verification'
import illustration from './assets/signup-illustration.svg'
import styles from './styles/signup.module.scss'

const Signup = () => {
    const [hide, setHide] = useState(false)
    const [userType, setUserType] = useState("")
    const [verify, setVerify] = useState(false)
    return (
        <div className={styles.container}>
            <h3>Sign Up</h3>
            <p>It's quick and easy</p>
            {verify ?
                <Verification />
                :
                <>
                    <UserType hide={hide} setHide={setHide} userType={userType} setUserType={setUserType} />
                    {userType ? <>
                        {userType === 'end_user' && <EndUserForm userType={userType} setVerify={setVerify} />}
                        {userType === 'farmer' && <FarmerForm userType={userType} setVerify={setVerify} />}
                        {userType === 'institution' && <InstitutionForm userType={userType} setVerify={setVerify} />}
                    </> :
                        <picture className={styles.illustration}>
                            <img src={illustration} alt="signup-illustration" />
                        </picture>
                    }
                </>
            }
        </div>
    )
}

export default Signup
