import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setLoader } from '../../state/slices/loader.slice'
import { searchFarmlandApi } from './apis/search.api'
import parse from 'html-react-parser'
import Map from '../Shared/Map'
import Farmer from '../Shared/Farmer'
import FarmerModal from '../Shared/FarmerModal'
import AddToPool from '../Shared/AddToPool'
import styles from './styles/search.module.scss'
import Pagination from '../Shared/Pagination'

const Search = ({ userInfo }) => {
    const [term, setTerm] = useState('')
    const [searchResultsTerm, setSearchResultsTerm] = useState('')
    const [markers, setMarkers] = useState([])
    const dispatch = useDispatch()
    const handleTerm = (e) => setTerm(e.target.value)
    const submit = async () => {
        setSearchResultsTerm('')
        dispatch(setLoader(true))
        const response = await searchFarmlandApi(term).then(response => {
            dispatch(setLoader(false))
            return response
        })
        if (response && response.status === 200) {
            setSearchResultsTerm(term)
            setMarkers(response.value || [])
            setTerm('')
        } else {
            setSearchResultsTerm('')
        }
    }

    //modal functionality
    const [showModal, setShowModal] = useState(false)
    const [selectedID, setSelectedID] = useState("")
    const [modalFarmer, setModalFarmer] = useState({})
    const closeModal = () => {
        setShowModal(false)
        setModalFarmer({})
        setSelectedID("")
    }
    useEffect(() => {
        const farmerDetails = markers.filter(farmerDetails => farmerDetails.user_id === selectedID)
        setModalFarmer(farmerDetails[0])
    }, [selectedID, markers]);
    //pagination
    const [markersPerPage, setMarkersPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const markersRef = useRef(null)
    const handleChange = (event, value) => {
        setPage(value)
        page !== value && markersRef.current.scrollIntoView({
            behavior: 'smooth'
        })
    }
    //Get current markers
    const indexOfLastMarker = page * markersPerPage;
    const indexOfFirstMarker = indexOfLastMarker - markersPerPage;
    const currentMarkers = markers.slice(indexOfFirstMarker, indexOfLastMarker)

    useEffect(() => {
        setCount(Math.ceil(markers.length / markersPerPage))
    }, [markers, markersPerPage])
    return (
        <div className={styles.container}>
            <h3>Search for a verified agropreneur</h3>
            <div className={styles.inputContainer}>
                <input
                    value={term}
                    onChange={handleTerm}
                    type="text"
                    name="term"
                    onKeyDown={({ key }) => key === "Enter" ? submit() : ''}
                />
                <button type="button" onClick={submit}>Go</button>
            </div>
            <p>AgroVerified covers over 200,000 verified farmers, aggregators and producers.</p>
            <div className={styles.middle}>
                {searchResultsTerm && <span className={styles.term}>Showing Search Results for: <span>{searchResultsTerm}</span></span>}
                {markers.length !== 0 && searchResultsTerm && userInfo.is_admin && <AddToPool farmerDetails={markers} all />}
            </div>
            <div className={styles.bottom}>
                {markers.length !== 0 && searchResultsTerm ?
                    <div ref={markersRef} className={styles.farmlands}>
                        <div className={styles.heading}>
                            <h4 className={styles.id}>Agro-ID</h4>
                            <h4 className={styles.farmer}>Basic Info</h4>
                            <h4 className={styles.location}>Location</h4>
                            <h4 className={styles.capacity}>Capacity</h4>
                            {/* <h4>Size</h4> */}
                            <h4 className={styles.farming_type}>Farming Type</h4>
                        </div>
                        {
                            markers.length !== 0 && currentMarkers.map((marker, index) => (
                                <Farmer key={index} marker={marker} setShowModal={setShowModal} setSelectedID={setSelectedID} />
                            ))
                        }
                    </div> :
                    <span className={styles.noResults}>
                        {searchResultsTerm && parse(`No results available for <span>${searchResultsTerm}</span>`)}
                    </span>
                }

                {markers.length !== 0 && searchResultsTerm &&
                    <div className={styles.pagination}>
                        <Pagination count={count} page={page} handleChange={handleChange} />
                    </div>
                }
                {(markers.length !== 0 && searchResultsTerm) && <div className={styles.map}>
                    <Map markers={markers} />
                </div>}
            </div>
            {(showModal && modalFarmer) && <FarmerModal modalFarmer={modalFarmer} setShowModal={setShowModal} closeModal={closeModal} />}
        </div>
    )
}

export default Search
