import Meta from '../components/Meta/Meta';
import Main from '../components/UserVerification/Main';

const VerifyAccount = () => {
    const meta = {
        title: "Verify Account",
        keywords: "agriculture, farmers, verification form, nigeria, verification, market place, community, agroverified",
        description: "Agroverified is a leading integrity ecosystem for agri-business across the world, we provide verified information on farmer’s products, farmlands, farmer’s geo-location, and crop types for transparent business transactions on a secure digital platform."
    }
    return (
        <>
            <Meta {...meta} />
            <Main />
        </>
    )
}

export default VerifyAccount
