import { Formik, Form } from 'formik'
import TextInput from "./TextInput";
import PasswordInput from "./PasswordInput";
import { setAuth } from "../../state/slices/auth.slice";
import { setLoader } from "../../state/slices/loader.slice"
import { useDispatch } from "react-redux";
import { useState } from "react";
import Cookies from "js-cookie";
import { loginInitialValues } from "./utils/forminitialvalues";
import { loginValidationSchema } from "./utils/formvalidation";
import { loginApi } from "./apis/login.api";
import styles from "./styles/loginform.module.scss"

const LoginForm = ({ setAuthState }) => {
    const [loginError, setLoginError] = useState(false)
    const dispatch = useDispatch()
    return (
        <>
            <Formik
                initialValues={loginInitialValues}
                validationSchema={loginValidationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    dispatch(setLoader(true))
                    const response = await loginApi(values, setLoginError).then(response => {
                        dispatch(setLoader(false))
                        setSubmitting(false)
                        return response
                    })
                    if (response && response.status === 200) {
                        const data = response.value
                        Cookies.set('auth', data, { expires: 7 })
                        const currentUser = Cookies.get('auth')
                        dispatch(setAuth(currentUser))
                        resetForm()
                    }
                }}
            >
                {formik =>
                    <Form className={styles.container}>
                        <TextInput autoFocus type='text' name='userName'
                            placeholder='Enter Your Email or Phone Number' />
                        <PasswordInput name='password' placeholder='Enter Your Password' />
                        {loginError && <div className={styles.loginError}>
                            <p>This password is incorrect or this user does not exist.</p>
                        </div>}
                        <button type="button" onClick={() => setAuthState('forgot')} className={styles.forgotPassword}>Forgot Password?</button>
                        <button className={styles.submit} disabled={formik.isSubmitting} type="submit">
                            Sign In
                        </button>
                    </Form>
                }
            </Formik>
        </>

    )
}

export default LoginForm
