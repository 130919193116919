import { gsap } from "gsap";


export const fadeEffect = (element, duration) => {
    gsap.from([element.current], {
        duration: duration,
        opacity: 0,
        // ease: "circ"
    });
}

export const toFade = (element, duration) => {
    gsap.to([element.current], {
        duration: duration,
        opacity: 0,
        // ease: "circ"
    });
}