import { termsofuse } from './utils/termsofuse'
import styles from './styles/termsofuse.module.scss'
import parse from 'html-react-parser'
import { HashLink } from 'react-router-hash-link'
import _ from 'lodash'

const TermsOfUse = () => {
    return (
        <section className={styles.container}>
            <h2>Agroverified's Terms and Conditions Of Use</h2>

            <p>These terms and conditions shall govern www.agroverified.com and set forth the legally binding terms and conditions for your use of the services (the “Service”) offered by Agrovalidate Limited. (“Agroverified” or the “Company”) and the website at <a href="https://www.agroverified.com">www.agroverified.com</a>.</p>

            <p>Your access to the use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service and all applicable laws and all conditions or policies referenced here (collectively, the “Terms of Use”)</p>

            <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service and that the Company may use any information the Company obtains about you following the provisions of the Privacy Policy.</p>

            <ol>
                {termsofuse && termsofuse.map((item, index) => (
                    <li key={index}>
                        {item.id}
                        <HashLink smooth to={`#${_.kebabCase(item.title)}`}>
                            {item.title}
                        </HashLink>
                    </li>))}
            </ol>

            <article>
                {termsofuse && termsofuse.map((item, index) => (
                    <section id={_.kebabCase(item.title)} key={index}>
                        <h3>{item.id} {item.title}</h3>
                        <p>{parse(item.content)}</p>
                    </section>
                ))}
            </article>
        </section>
    )
}

export default TermsOfUse
