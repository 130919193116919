import TextInput from './TextInput'
import { routes } from '../../../global/utils/routeNames'
import styles from '../styles/inputscontainer.module.scss'
import UploadInput from './UploadInput'
import { Link } from 'react-router-dom'
import SelectInput from '../../UserUpdateProfile/forms/SelectInput'

const InstitutionVerificationForm = ({ formik, setCACCertificate }) => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.company}>
                    <h3>Company Information</h3>
                    <div className={styles.double}>
                        <TextInput label="company name" name="institution_name" type="text" placeholder="" />
                        {/* <TextInput label="business category" name="business_category" type="text" placeholder="" /> */}
                        <div className={styles.customInput}>
                            <label htmlFor="">Business Category</label>
                            <SelectInput formik={formik} field="business_category"
                                defaultValue={formik.values.business_category}
                                values={["bank", "Non-bank financial institution", "utility company", "retailer", "telecommunication", "insurance", "others"]} />
                        </div>
                    </div>
                    <div className={styles.double}>
                        <TextInput label="CBN number" name="cbn_number" type="text" placeholder="" />
                        <TextInput label="RC number" name="institution_rc_number" type="text" placeholder="" />
                    </div>
                    <div className={styles.customInput}>
                        <label htmlFor="">Upload CAC Certificate</label>
                        <UploadInput id="cac_certificate_url"
                            accept="image/*,.pdf"
                            setImage={setCACCertificate}
                            formik={formik}
                            field="cac_certificate_url"
                            defaultValue={formik.values.cac_certificate}
                            maxCount={1} />
                    </div>
                    {/* </div> */}
                </div>
                <div className={styles.contact}>
                    <h3>Contact Information</h3>
                    <div className={styles.double}>
                        <TextInput label="company email address" name="institution_email" type="email" placeholder="" />
                        <TextInput label="company phone number" name="institution_phone_number" type="tel" placeholder="" />
                    </div>
                    <div className={styles.double}>
                        <TextInput label="managing director's name" name="director_name" type="text" placeholder="" />
                        <TextInput label="name of primary contact" name="name_of_primary_contact" type="text" placeholder="" />
                    </div>
                    <TextInput label="head office address" name="head_office_address" type="text" placeholder="" />
                </div>
                <div className={styles.declaration}>
                    <h3>Declaration</h3>
                    <ol>
                        <li>This is a confirmation of our registration with agroverified and an agreement to pay the associated membership and subscription fees as at when due.</li>
                        <li>We also confirm that all information provided is correct as at the time of filling this form.</li>
                    </ol>
                    <p><input type="checkbox" name="agreement" id="" required /> <span>We further confirm that we have read Agroverified's <Link to={routes.terms_of_use}>Term's of Use</Link> and <a href="" target="_blank" rel="noopener noreferrer">Privacy Policy</a></span>.</p>
                </div>
            </div>
            <div className={styles.buttons}>
                <button className={styles.submit} type="submit">Submit</button>
            </div>
        </>
    )
}

export default InstitutionVerificationForm
