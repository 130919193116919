import Avatar from '@material-ui/core/Avatar';
import { IoMdRemoveCircle, IoMdAddCircle } from 'react-icons/io'
import styles from './styles/member.module.scss'

const Member = ({ member, filter, setShowModal, setModalAction, setEmployeeId, userInfo }) => {
    const handleModal = () => {
        setEmployeeId(member._id)
        setShowModal(true)
        if (filter === "active") {
            setModalAction('disable')
        } else if (filter === "inactive") {
            setModalAction('restore')
        }
    }
    return (
        <>
            {member._id &&
                <div className={styles.container}>
                    <span className={styles.user}>
                        {member.photo_url ?
                            <Avatar className={styles.avatar} alt={member.first_name} src={member.photo_url} /> :
                            <Avatar className={styles.avatar}>{member.first_name.charAt(0)}</Avatar>
                        }
                        <span className={styles.userName}>{member.first_name + ' ' + member.last_name}</span>
                    </span>
                    {userInfo.is_admin && <button onClick={handleModal}><span className={styles.text}>{filter === "active" ? 'Disable' : 'Restore'} </span>
                        <span className={styles.icon} title={filter}>{filter === "active" ? <IoMdRemoveCircle /> : filter === "inactive" ? <IoMdAddCircle /> : null}</span></button>}
                </div>}
        </>
    )
}

export default Member
