import { useDispatch } from 'react-redux'
import { setShowModal, setStatus } from '../../state/slices/modal.slice'
import ForgotPasswordForm from './ForgotPasswordForm';
import styles from './styles/login.module.scss'

const ForgotPassword = ({ setAuthState }) => {
    const dispatch = useDispatch()

    // login/signup state
    const openModal = (status) => {
        dispatch(setShowModal(true));
        dispatch(setStatus(status));
    }

    return (
        <div className={styles.container}>
            <h3>Forgot Password</h3>
            <p>Enter phone number to reset password</p>
            <ForgotPasswordForm setAuthState={setAuthState} />
            <div className={styles.bottom}>
                <p>New to Agroverified?</p>
                <button onClick={() => openModal("signup")} className={styles.signup}>Create An Account</button>
            </div>
        </div>
    )
}

export default ForgotPassword
