import * as Yup from 'yup'

export const loginValidationSchema = Yup.object().shape({
    userName: Yup.string().required('field is required'),
    password: Yup.string().min(8).max(15).required("password is required")
})

export const resetValidationSchema = Yup.object().shape({
    token: Yup.string().max(6).required('field is required'),
    password: Yup.string().min(8).max(15).required("password is required")
})

export const forgotValidationSchema = Yup.object().shape({
    phone_number: Yup.string()
        .matches('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,7}$',
            { message: "invalid phone number" })
        .required('phone number is required')
})

export const endUserValidationSchema = Yup.object().shape({
    first_name: Yup.string().required("first name is required"),
    last_name: Yup.string().required("last name is required"),
    email: Yup.string().email('invalid email address'),
    password: Yup.string().min(8).max(15).required("password is required"),
    phone_number: Yup.string()
        .matches('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,7}$',
            { message: "invalid phone number" })
        .required('phone number is required')
})


export const farmerValidationSchema = Yup.object().shape({
    first_name: Yup.string().required("first name is required"),
    last_name: Yup.string().required("last name is required"),
    email: Yup.string().email('invalid email address'),
    password: Yup.string().min(8).max(15).required("password is required"),
    phone_number: Yup.string()
        .matches('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,7}$',
            { message: "invalid phone number" })
        .required('phone number is required'),
    location: Yup.string()
})


export const institutionValidationSchema = Yup.object().shape({
    first_name: Yup.string().required("name is required"),
    institution_rc_number: Yup.string().required("rc number is required"),
    email: Yup.string().email('invalid email address'),
    password: Yup.string().min(8).max(15).required("password is required"),
    phone_number: Yup.string()
        .matches('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,7}$',
            { message: "invalid phone number" })
        .required('phone number is required'),
    institution_location: Yup.string()
})

export const verificationValidationSchema = Yup.object().shape({
    code_1: Yup.string().required('field is required').max(1),
    code_2: Yup.string().required('field is required').max(1),
    code_3: Yup.string().required('field is required').max(1),
    code_4: Yup.string().required('field is required').max(1),
    code_5: Yup.string().required('field is required').max(1),
    code_6: Yup.string().required('field is required').max(1)
})