import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLoader } from '../../state/slices/loader.slice'
import { setMembersReload } from '../../state/slices/members.slice'
import { addMemberApi } from './apis/addmember.api'
import styles from './styles/addmember.module.scss'

const AddMember = ({ userInfo, setNewMember }) => {
    const [member, setMember] = useState('')
    const dispatch = useDispatch()
    const submit = async () => {
        dispatch(setLoader(true))
        const response = await addMemberApi(member, userInfo.institution_id).then(response => {
            dispatch(setMembersReload(true))
            dispatch(setLoader(false))
            return response
        })
        if (response && response.status === 200) {
            setMember('')
        }
    }
    return (
        <div className={styles.container}>
            <span onClick={() => setNewMember(false)} className={styles.back}>Back</span>
            <h3>Add an employee</h3>
            <div className={styles.inputContainer} onKeyDown={({ key }) => {
                key === "Enter" && submit()
            }}>
                <input
                    value={member}
                    onChange={(e) => setMember(e.target.value)}
                    name="member"
                    type="email" />
                <button onClick={submit}>Add</button>
            </div>
            <p>Link a user to your institution.</p>
        </div>
    )
}

export default AddMember
