import ReactDOM from 'react-dom'
import { NavLink } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io'
import { routes } from '../../global/utils/routeNames';
import styles from './styles/mobilemenu.module.scss';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';

const duration = 500;

const defaultStyle = {
    transition: `all ${duration}ms ease-out`,
    opacity: 0,
    transform: "translateX(100%)"
}

const transitionStyles = {
    entering: { opacity: 1, transform: "translateX(0)" },
    entered: { opacity: 1, transform: "translateX(0)" },
    exiting: { opacity: 0, transform: "translateX(100%)" },
    exited: { opacity: 0, transform: "translateX(100%)" },
};


const MobileMenu = ({ auth, logOut, showMenu, setShowMenu, openModal }) => {
    const overlayRef = useRef(null)
    const menuRef = useRef(null)
    const closeMenu = (e) => {
        if (overlayRef.current === e.target) {
            setShowMenu(false)
        }
    }

    return ReactDOM.createPortal(
        <Transition
            in={showMenu}
            timeout={duration}
            mountOnEnter={true}
            unmountOnExit={true}
            appear={showMenu}
        >
            {state => (
                <div style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                }} onClick={closeMenu} ref={overlayRef} className={styles.container}>
                    <div ref={menuRef} onClick={() => setShowMenu(false)} className={styles.menu}>
                        <span className={styles.icon} onClick={() => setShowMenu(false)}><IoMdClose /></span>
                        <nav>
                            <NavLink exact to={routes.home} activeClassName={styles.active}><span>Home</span></NavLink>
                            <NavLink to={routes.about} activeClassName={styles.active}><span>About</span></NavLink>
                            {/* <NavLink to={routes.market} activeClassName={styles.active}><span>Market Place</span></NavLink>
                            <NavLink to={routes.dataplace} activeClassName={styles.active}><span>Data Place</span></NavLink> */}
                            {/* <NavLink to={routes.caution_list} activeClassName={styles.active}><span>Caution List</span></NavLink>
                            <NavLink to={routes.community} activeClassName={styles.active}><span>Community</span></NavLink> */}
                            {auth.status === "success" && <NavLink to={routes.profile} activeClassName={styles.active}><span>Profile</span></NavLink>}
                            <NavLink to={routes.contact} activeClassName={styles.active}><span>Contact</span></NavLink>
                        </nav>
                        <div className={styles.buttons}>
                            {auth.status !== "success" ? <>
                                <NavLink to={routes.auth} activeClassName={styles.active}>Sign In</NavLink>
                                {/* <button onClick={() => openModal("signup")} className={styles.signup}>Sign Up</button> */}
                            </> :
                                <button onClick={logOut} className={styles.signup}>Log Out</button>
                            }
                        </div>
                    </div>
                </div>
            )}
        </Transition>,
        document.getElementById('portal'))
}

export default MobileMenu
