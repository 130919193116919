import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        value: {},
        isAdmin: false
    },
    reducers: {
        setAuth: (state, { payload }) => {
            state.value = JSON.parse(payload)
        },
        setIsAdmin: (state, { payload }) => {
            state.isAdmin = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setAuth, setIsAdmin } = authSlice.actions

export default authSlice.reducer