import swal from "sweetalert";
import http from '../../../http-common'

export const forgotPasswordApi = async (values) => {
    const request = {
        method: 'POST',
        url: "/auth/recover",
        data: JSON.stringify(values)
    };
    try {
        let response = await http({
            ...request
        }).catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log(err.response)
                swal({
                    icon: 'error',
                    text: err.response.data || err.response.data.error || err.response.data.message || Object.values(err.response.data.error)[0],
                    timer: 4000,
                    button: false,
                });
            } else if (err.request) {
                // client never received a response, or request never left
                console.log(err.request)
            } else {
                // anything else
                console.log(err)
            }
        })

        if (response && response.status !== 401) {
            const data = response.data
            swal({
                icon: 'success',
                text: data,
                timer: 4000,
                button: false,
            });
            return response = {
                value: data,
                status: 200
            }
        }
    } catch (e) {
        console.log(e)
    }
}