import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const heroGsap = (container, tinytext, largetext, banner) => {
    const tl = gsap.timeline()
    // gsap.from([container.current], {
    //     y: -50,
    //     opacity: 0,
    //     duration: 0.5,
    // })
    // gsap.from([tinytext.current], {
    //     xPercent: -100,
    //     opacity: 0,
    //     duration: 0.5,
    //     delay: 0.5,
    // })
    // gsap.from([largetext.current], {
    //     xPercent: 100,
    //     opacity: 0,
    //     duration: 0.5,
    //     delay: 1,
    // })
    gsap.from([banner.current], {
        xPercent: -100,
        opacity: 0,
        duration: 0.5,
        // delay: 1.5,
    })
}