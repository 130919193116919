import styles from './styles/loader.module.scss'

const Loader = () => {
    return (
        <div className={styles.container}>
            <div className={styles.spinner}></div>
        </div>
    )
}

export default Loader
