import Profile from './Profile'
import BasicInformation from './forms/BasicInformation'
import Farmland from './forms/Farmland'
import Products from './forms/Products'
import Records from './forms/Records'
import Tabs from './Tabs'
import styles from './styles/panels.module.scss'
import { useState } from 'react'

const Forms = ({ userInfo }) => {
    const [tab, setTab] = useState(0)

    return (
        <div className={styles.container}>
            <Tabs tab={tab} setTab={setTab} userInfo={userInfo} />
            {tab === 0 && <Profile userInfo={userInfo} />}
            {(tab === 1 && userInfo.user_type !== "institution") && <BasicInformation setTab={setTab} userInfo={userInfo} />}
            {(tab === 2 && userInfo.user_type === "farmer") && <Farmland setTab={setTab} userInfo={userInfo} />}
            {(tab === 3 && userInfo.user_type === "farmer") && <Products setTab={setTab} userInfo={userInfo} />}
            {(tab === 4 && userInfo.user_type === "farmer") && <Records setTab={setTab} userInfo={userInfo} />}
        </div>
    )
}

export default Forms
