import { useRef, useLayoutEffect } from 'react'
import { HashLink } from 'react-router-hash-link'
import { contactGsap } from './animations'
import styles from './styles/contact.module.scss'

const Contact = () => {
    const contactUsRef = useRef(null)
    const contactUsTextRef = useRef(null)
    const contactUsMapRef = useRef(null)
    useLayoutEffect(() => {
        contactGsap(contactUsRef, contactUsTextRef, contactUsMapRef)
    }, [])
    return (
        <div ref={contactUsRef} className={styles.container}>
            <section ref={contactUsTextRef} className={styles.contactus}>
                <h3>Contact Us</h3>
                <div>
                    <p>We are always happy to hear from you, reach us through any of these media platforms.</p>
                    <p>Addresses: <a title="Plot 18, Block 15 Bashorun Okusanya Avenue off Admiralty way, Lekki Phase 1" href="https://goo.gl/maps/poxGvVV3egM8tegR6" target="_blank" rel="noopener noreferrer">Lagos, Nigeria</a> | <a title="Suite 12, 3rd Floor, Candelux plaza, Adjacent National Open University, Dutse Junction, Kubwa Express, Abuja FCT" href="https://goo.gl/maps/Qed5NNK2z2tLNadb6" target="_blank" rel="noopener noreferrer">Abuja, Nigeria</a></p>
                    <p>Email: <a href="mailto:info@agroverified.com">info@agroverified.com</a> | <a href="mailto:support@agroverified.com">support@agroverified.com</a></p>
                    <p>Phone: <a href="tel:+2347032111095">+234 703 211 1095</a></p>
                    <p>Open hours: <span>Mon - Fri: 8 AM to 5 PM</span></p>
                    <p>Website: <HashLink smooth to="/#">www.agroverified.com</HashLink></p>
                </div>
            </section>
            <div ref={contactUsMapRef} className={styles.mapContainer}>
                <iframe title="map" src="https://www.google.com/maps/d/u/0/embed?mid=1vM7q1nwJGAzgTXQYAUPYTNvo8TjMRgaE" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe>
            </div>
        </div>
    )
}

export default Contact
