import styles from './styles/layout.module.scss';
import Navbar from './Navbar';
import Hero from './Hero';
import ScrollToTopButton from './ScrollToTopButton';
import ScrollToTopOnLoad from './ScrollToTopOnLoad';
import Modal from '../Auth/Modal'
import Loader from '../Shared/Loader';
import Footer from './Footer';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AccountLayout from './AccountLayout';
import { routes } from "../../global/utils/routeNames";

const Layout = ({ children, userInfo }) => {
    const location = useLocation()
    const showModal = useSelector(state => state.modal.showModal)
    const loader = useSelector(state => state.loader.value)

    return (
        <div className={styles.container}>
            <Navbar />
            {![routes.home, routes.auth].includes(location.pathname) && <Hero />}
            <main>
                {
                    !location.pathname.includes('/user/') ?
                        children :
                        <AccountLayout userInfo={userInfo}>
                            {children}
                        </AccountLayout>
                }
            </main>
            <ScrollToTopOnLoad />
            <ScrollToTopButton />
            {showModal && <Modal />}
            {loader && <Loader />}
            {location.pathname !== routes.auth && <Footer />}
        </div>
    )
}

export default Layout
