export const partners = [{
    id: "1",
    img: 'https://res.cloudinary.com/agroverifiednig/image/upload/v1629534450/website/home/limecapital_d9fzxb.jpg',
    link: ''
}, {
    id: "2",
    img: 'https://res.cloudinary.com/agroverifiednig/image/upload/v1629534450/website/home/unitedcapital_jihidr.jpg',
    link: ''
}, {
    id: "3",
    img: 'https://res.cloudinary.com/agroverifiednig/image/upload/v1629534450/website/home/truecode_kwglmn.jpg',
    link: ''
}]
