import { useDispatch } from 'react-redux'
import { setShowModal, setStatus } from '../../state/slices/modal.slice'
import ResetPasswordForm from './ResetPasswordForm';
import styles from './styles/loginform.module.scss'

const ResetPassword = ({ setAuthState }) => {
    const dispatch = useDispatch()

    // login/signup state
    const openModal = (status) => {
        dispatch(setShowModal(true));
        dispatch(setStatus(status));
    }

    return (
        <div className={styles.container}>
            <h3>Reset Password</h3>
            <ResetPasswordForm setAuthState={setAuthState} />
            <div className={styles.bottom}>
                <p>New to Agroverified?</p>
                <button onClick={() => openModal("signup")} className={styles.signup}>Create An Account</button>
            </div>
        </div>
    )
}

export default ResetPassword
