import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const agropreneursGsap = (container, heading, agropreneurs) => {
    const tl = gsap.timeline()
    const scrollTrigger = {
        trigger: [container.current],
        toggleActions: "restart none none reset",
        start: 'center bottom',
        // end: () => `+=${container.current.offsetHeight}`,
        // scrub: true
    }
    gsap.from([heading.current], {
        scrollTrigger,
        xPercent: -50,
        opacity: 0,
        duration: 0.5,
        ease: 'power3.out'
    })
    gsap.from([agropreneurs.current], {
        scrollTrigger,
        xPercent: 100,
        opacity: 0,
        duration: 0.5,
        delay: 0.5,
        transform: 'scale(0.2)',
        ease: 'power3.out'
    })
}