import Meta from '../components/Meta/Meta';
import Main from '../components/TOU & PP/TermsOfUse';

const TermsOfUse = () => {
    const meta = {
        title: "Terms Of Use",
        keywords: "agriculture, farmers, terms of use, nigeria, verification, market place, community, agroverified",
        description: "Agroverified is a leading integrity ecosystem for agri-business across the world, we provide verified information on farmer’s products, farmlands, farmer’s geo-location, and crop types for transparent business transactions on a secure digital platform."
    }
    return (
        <>
            <Meta {...meta} />
            <Main />
        </>
    )
}

export default TermsOfUse
