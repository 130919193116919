import { useRef, useLayoutEffect } from 'react'
import { Formik, Form } from 'formik'
import { initialValues } from './utils/forminitialvalues'
import { validationSchema } from './utils/formvalidation'
import { FiSend } from 'react-icons/fi'
import emailjs from 'emailjs-com';
import TextInput from './TextInput'
import styles from './styles/getintouch.module.scss'
import { getInTouchGsap } from './animations'

const Getintouch = () => {
    const getInTouchRef = useRef(null)
    const getInTouchHeadingRef = useRef(null)
    const getInTouchParagraphRef = useRef(null)
    const getInTouchFormRef = useRef(null)
    useLayoutEffect(() => {
        getInTouchGsap(getInTouchRef, getInTouchHeadingRef, getInTouchParagraphRef, getInTouchFormRef)
    }, [])
    return (
        <div id="getintouch" ref={getInTouchRef} className={styles.container}>
            <section>
                <h3 ref={getInTouchHeadingRef}>Get in touch</h3>
                <p ref={getInTouchParagraphRef}>Send us a message and we'll get back to you.</p>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        await emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID,
                            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                            "#contact-form",
                            process.env.REACT_APP_EMAILJS_USER_ID)
                            .then((result) => {
                                console.log(result.text);
                                setSubmitting(false)
                                resetForm()
                            }, (error) => {
                                console.log(error.text);
                            });
                    }}
                >
                    {formik =>
                        <Form id="contact-form" ref={getInTouchFormRef}>
                            <div className={styles.double}>
                                <TextInput label="Name" name="name" type="text" placeholder="Name" />
                                <TextInput label="Contact" name="contact" type="text" placeholder="Email or Phone Number" />
                            </div>
                            <TextInput label="Subject" name="subject" type="text" placeholder="Subject" />
                            <TextInput as="textarea" label="Message" name="message" type="text" placeholder="How can we help?" />
                            <button disabled={formik.isSubmitting} type="submit">Send <span className={styles.icon}><FiSend /></span> </button>
                        </Form>
                    }
                </Formik>

            </section>
        </div>
    )
}

export default Getintouch
