import MessageModalContent from './MessageModalContent'
import { createPortal } from 'react-dom'
import styles from './styles/messagemodal.module.scss'
import { useRef } from 'react'

const MessageModal = ({ setShowModal, pool }) => {
    const modalRef = useRef()
    const closeModalTarget = (e) => {
        if (modalRef.current === e.target) {
            setShowModal(false)
        }
    }
    return createPortal(
        <div ref={modalRef} onClick={closeModalTarget} className={styles.container}>
            <MessageModalContent setShowModal={setShowModal} pool={pool} />
        </div>
        , document.getElementById('portal'))
}

export default MessageModal
