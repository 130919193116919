import { useLocation } from 'react-router'
import styles from './styles/hero.module.scss'

const Hero = () => {
    const location = useLocation()
    let heading = location.pathname.split('/').pop().split('-').join(" ")
    return (
        <div className={styles.container}>
            {/* <h2>{heading !== "dashboard" ? heading : 'profile'}</h2> */}
            <h2>{heading}</h2>
        </div>
    )
}

export default Hero
