import { createSlice } from '@reduxjs/toolkit'

export const dropdownSlice = createSlice({
    name: 'dropdown',
    initialState: {
        value: false,
    },
    reducers: {
        change: (state) => {
            state.value = !state.value
        },
        set: (state, { payload }) => {
            state.value = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { change, set } = dropdownSlice.actions

export default dropdownSlice.reducer