import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);


export const partnersGsap = (container, heading, partner) => {
    const tl = gsap.timeline()
    const scrollTrigger = {
        trigger: [container.current],
        toggleActions: "restart none none reset",
        start: 'center bottom',
        // end: () => `+=${container.current.offsetHeight}`,
        // scrub: true
    }
    gsap.from([heading.current], {
        scrollTrigger,
        xPercent: -60,
        opacity: 0,
        duration: 0.5
    })
    gsap.from('#partner', {
        scrollTrigger,
        stagger: {
            amount: 1.5,
        },
        opacity: 0,
        scale: 0.2,
        skew: 1.2,
        ease: "power1.inOut",
        duration: 0.5,
        delay: 0.5
    })
}