import { useEffect, useLayoutEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { fadeEffect } from '../../global/animations/fade'
import styles from './styles/agropreneur.module.scss'

const Agropreneur = ({ agropreneur, fade, index, active, setHoveredIndex }) => {
    const agropreneurRef = useRef(null)
    useLayoutEffect(() => {
        if (fade && index === active) {
            fadeEffect(agropreneurRef, 2)
        }
    }, [fade, index, active])
    useEffect(() => {
        const hovered = () => {
            setHoveredIndex(index)
        }
        const notHovered = () => {
            setHoveredIndex(-1)
        }
        const currentAgropreneur = agropreneurRef.current
        currentAgropreneur.addEventListener('mouseenter', hovered)
        currentAgropreneur.addEventListener('mouseleave', notHovered)
        return () => {
            currentAgropreneur.removeEventListener('mouseenter', hovered)
            currentAgropreneur.removeEventListener('mouseleave', notHovered)
        }
    }, [index, setHoveredIndex])
    return (
        <div ref={agropreneurRef} className={styles.container}>
            <div className={styles.box}>
                <picture>
                    <img src={agropreneur.photo_url} loading="lazy" alt="agropreneur" />
                </picture>
                <div className={styles.info}>
                    <h4>{agropreneur.first_name + ' ' + agropreneur.last_name}</h4>
                    <div>
                        <p>{agropreneur.user_type && agropreneur.user_type}</p>
                        {/* <div className={styles.buttons}>
                            <Link to="/market-place"><button>Verify</button></Link>
                            <Link to="/community"><button>Chat</button></Link>
                        </div> */}
                    </div>
                </div>
            </div>
            <section className={styles.name}>
                <h4>{agropreneur.first_name + ' ' + agropreneur.last_name}</h4>
            </section>
        </div>
    )
}

export default Agropreneur
