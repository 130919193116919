import Signup from './Signup'
import { useDispatch } from 'react-redux'
import { setShowModal, setStatus } from '../../state/slices/modal.slice'
import { useSelector } from "react-redux"
import { IoMdClose } from 'react-icons/io'
import styles from './styles/modalcontent.module.scss'

const ModalContent = () => {
    const form = useSelector(state => state.modal.status)
    const dispatch = useDispatch()
    const closeModal = () => {
        dispatch(setStatus(""))
        dispatch(setShowModal(false))
    }
    return (
        <div className={styles.container}>
            <span onClick={closeModal} className={styles.close}><IoMdClose className={styles.closeIcon} /></span>
            {form === 'signup' ? <Signup /> :
                null}
        </div>
    )
}

export default ModalContent
