import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const mainGsap = (container, text, form) => {
    gsap.from([text.current], {
        xPercent: -100,
        opacity: 0,
        ease: 'slow.out',
        duration: 0.5,
    })
    gsap.from([form.current], {
        opacity: 0,
        yPercent: 100,
        ease: 'sine.out',
        duration: 0.5,
        delay: 0.5,
    })
}