import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

const Meta = ({ title, description, keywords }) => {
    return (
        <Helmet>
            <meta
                name="description"
                content={description}
            />
            <meta name="keywords" content={keywords} />
            <title>AgroVerified | {_.upperFirst(title)}</title>
        </Helmet>
    )
}

export default Meta
