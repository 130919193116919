import { useField } from 'formik';
import styles from '../styles/textinput.module.scss'

const TextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <div className={styles.container}>
            <label className={meta.touched && meta.error ? styles.labelError : ''} htmlFor={props.id || props.name}>{label}</label>
            {props.disabled && <input className={styles.disabled} {...field} {...props} />}
            {!props.disabled && <input className={meta.touched && meta.error ? styles.inputError : ''} {...field} {...props} />}
            {meta.touched && meta.error ? (
                <div className={styles.error}>{meta.error}</div>
            ) : null}
        </div>
    );
};

export default TextInput
