import { createSlice } from '@reduxjs/toolkit'

export const institutionPoolsSlice = createSlice({
    name: 'institution pools',
    initialState: {
        value: [],
        reload: true
    },
    reducers: {
        setInstitutionPools: (state, { payload }) => {
            state.value = payload
        },
        setPoolsReload: (state, { payload }) => {
            state.reload = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setInstitutionPools, setPoolsReload } = institutionPoolsSlice.actions

export default institutionPoolsSlice.reducer