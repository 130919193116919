import { useLocation, Route, Redirect } from "react-router-dom";
import { routes } from "../global/utils/routeNames";
import Cookies from "js-cookie"

const ProtectedRoute = ({ institution, ...props }) => {
    const location = useLocation();
    const auth = JSON.parse(Cookies.get('auth') || '{}')
    const institutionId = auth.data?.institution_id

    const condition = institution ?
        auth.status === "success" && institutionId
        : auth.status === "success"

    return condition ? (
        <Route {...props} />
    ) : (
        <Redirect
            to={{
                pathname: (institution && auth.status === "success" && !institutionId) ? routes.home : routes.auth,
                state: { from: location }
            }}
        />
    );
};
export default ProtectedRoute
