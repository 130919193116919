import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//pages
import Auth from "../pages/Auth";
import Home from "../pages/Home";
import About from "../pages/About";
// import Database from "../pages/Database";
// import Dataplace from "../pages/Dataplace";
// import MarketPlace from "../pages/MarketPlace";
// import CautionList from "../pages/CautionList";
import Contact from '../pages/Contact'
// import Community from "../pages/Community";
// import StoryDetails from "../pages/StoryDetails";
// import NewStory from "../pages/NewStory";
// import Messages from "../pages/Messages";
import TermsOfUse from "../pages/TermsOfUse";
import Profile from "../pages/UpdateProfile";
import Verification from "../pages/Verification";
import InstitutionSearch from "../pages/InstitutionSearch";
import InstitutionMembers from "../pages/InstitutionMembers";
import InstitutionPools from "../pages/InstitutionPools";
import InstitutionPoolMembers from "../pages/InstitutionPoolMembers";
import Page404 from "../pages/404";

import Layout from "../components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../state/slices/auth.slice";
import { setUserInfo } from "../state/slices/userinfo.slice";
import { routes } from "../global/utils/routeNames";
import Cookies from "js-cookie"
import ProtectedRoute from './ProtectedRoute'
import { loggedInUserApi } from "./apis/loggedInUserApi.api";

const App = () => {
  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.userInfo.value)
  const auth = useSelector(state => state.auth.value)

  useEffect(() => {
    const currentUser = Cookies.get('auth') || "{}"
    dispatch(setAuth(currentUser))
    const getUser = async () => {
      const response = await loggedInUserApi().then(response => response)
      if (response && response.status === 200) {
        const user = response.value || "{}"
        user.user_type = user.is_deleted ? "end_user" : user.user_type
        dispatch(setUserInfo(user))
      }
    }
    JSON.parse(currentUser).data?.token?.token && getUser()
  }, [dispatch, userInfo._id, userInfo.institution_id, auth.data?.token?.token])

  return (
    <Router>
      <Layout userInfo={userInfo}>
        <Switch>
          <Route exact path={routes.home} component={Home} />
          <Route exact path={routes.auth} component={Auth} />
          <Route exact path={routes.about} component={About} />
          {/* <Route exact path={routes.market} component={MarketPlace} />
          <Route exact path={routes.dataplace} component={Database} />
          <Route exact path={routes.caution_list} component={CautionList} /> */}
          <Route exact path={routes.contact} component={Contact} />
          {/* <Route exact path={routes.community} component={Community} /> */}
          {/* <Route exact path={routes.stories + '/:story'} component={StoryDetails} /> */}
          {/* <ProtectedRoute exact path={routes.new_story} component={NewStory} /> */}
          {/* <ProtectedRoute exact path={routes.messages} component={Messages} /> */}
          <Route exact path={routes.terms_of_use} component={TermsOfUse} />
          <ProtectedRoute exact path={routes.verify_account} component={Verification} />
          <ProtectedRoute exact path={routes.profile} component={Profile} />
          <ProtectedRoute institution exact path={routes.institution_search} component={InstitutionSearch} />
          <ProtectedRoute institution exact path={routes.institution_members} component={InstitutionMembers} />
          <ProtectedRoute institution exact path={routes.institution_pools} component={InstitutionPools} />
          <ProtectedRoute institution exact path={routes.institution_pools_members()} component={InstitutionPoolMembers} />
          <Route path="*" component={Page404} />
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
