import { ErrorMessage, Field, Form, Formik } from 'formik'
import { IoMdClose } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import { setPoolsReload } from '../../state/slices/pools.slice'
import { messageInitialValues } from './utils/forminitialvalues'
import { messageValidationSchema } from './utils/formvalidationschema'
import { messagePoolApi } from './apis/messagepool.api'
import { setLoader } from '../../state/slices/loader.slice'
import { FcInfo } from 'react-icons/fc'
import styles from './styles/messagemodalcontent.module.scss'
import { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip';
import TokenModal from './TokenModal'

const MessageModalContent = ({ setShowModal, pool }) => {
    const dispatch = useDispatch()
    const [isToken, setIsToken] = useState(false)
    const [tokenCaution, setTokenCaution] = useState(false)
    const [showTokenModal, setTokenModal] = useState(false)
    const handleToken = () => {
        setIsToken(prev => !prev)
    }
    useEffect(() => {
        setTokenCaution(pool.sent_token && isToken)
    }, [isToken, pool.sent_token])
    return (
        <div className={styles.container}>
            <span onClick={() => setShowModal(false)} className={styles.close}><IoMdClose /></span>
            <h3>Compose Pool Message</h3>
            <Formik onSubmit={async (values, { resetForm, setSubmitting }) => {
                dispatch(setLoader(true))
                const response = await messagePoolApi(pool._id, values.message, isToken).then(response => {
                    dispatch(setPoolsReload(true))
                    dispatch(setLoader(false))
                    setSubmitting(false)
                    return response
                })
                if (response.status === 200) {
                    resetForm()
                    setTokenModal(false)
                    setShowModal(false)
                }
            }}
                initialValues={messageInitialValues}
                validationSchema={messageValidationSchema}>
                {formik =>
                    <Form>
                        <div className={styles.message}>
                            <label className={(formik.errors.message && formik.touched.message) ? styles.errorLabel : ''} htmlFor="message">Message</label>
                            <Field id="message" className={(formik.errors.message && formik.touched.message) ? `${styles.messageInput} ${styles.errorInput}` : styles.messageInput} as="textarea" name="message" placeholder="enter message body" />
                            <span className={styles.errorMessage}><ErrorMessage name="message" /></span>
                        </div>
                        <div className={styles.token}>
                            <input id="include_token" defaultChecked={isToken} onChange={handleToken} className={styles.checkbox} type="checkbox" name="include_token" />
                            <label htmlFor="include_token">
                                Do you want to include pool tokens in your message?
                            </label>
                            <FcInfo className={styles.icon} data-tip="a pool token is the code presented to an official by recipients of your message as a means of identification" data-type="info" data-effect="solid" data-offset="{'top': 10}" />
                        </div>
                        <button type={!tokenCaution ? "submit" : "button"} onClick={!tokenCaution ? formik.handleSubmit : () => setTokenModal(true)} disabled={formik.isSubmitting}>Send Message</button>
                        <ReactTooltip />
                        {showTokenModal && <TokenModal formik={formik} setShowModal={setTokenModal} tokenCaution={tokenCaution} />}
                    </Form>
                }
            </Formik>
        </div>
    )
}

export default MessageModalContent
