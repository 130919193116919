import _ from 'lodash'
import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'
import styles from './styles/addtopool.module.scss';

import { addToPoolApi } from './apis/addtopool.api'
import { useDispatch } from 'react-redux'
import { setLoader } from '../../state/slices/loader.slice'
import { setPoolsReload } from '../../state/slices/pools.slice'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
}));

export default function MenuListComposition({ farmerDetails, closeModal, all }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const pools = useSelector(state => state.institutionPools.value)

    const handleToggle = () => {
        dispatch(setPoolsReload(true))
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    //add to pool
    const dispatch = useDispatch()
    const AddToPool = async (event, poolId) => {
        dispatch(setLoader(true))
        const response = await addToPoolApi(poolId, farmerDetails).then(response => {
            dispatch(setLoader(false))
            handleClose(event)
            return response
        })
        if (response && response.status === 200) {
            dispatch(setPoolsReload(true))
            closeModal && closeModal()
        }
    }

    return (
        <div className={classes.root}>
            <div className={styles.container}>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    Add {all && 'All'} To Pool
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper className={styles.paper}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        {pools.length !== 0 && pools.map((pool, index) => (
                                            <MenuItem key={index} onClick={(event) => AddToPool(event, pool._id)}>
                                                {_.capitalize(pool.pool_name)}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}