import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const agroverifiedApi = createApi({
    reducerPath: 'agroverifiedApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://agro-verified.herokuapp.com/api/' }),
    endpoints: (builder) => ({
        getUserInfo: builder.query({
            query: (token) => ({
                url: `user/me/dashboard`,
                headers: {
                    authorization: 'Bearer ' + token
                }
            }),
            // Pick out data and prevent nested properties in a hook or selector
            // transformResponse: (response) => response.data,
            // // `result` is the server response
            // providesTags: (result, error, id) => [{ type: 'userInfo', id }],
            // // trigger side effects or optimistic updates
            // onQueryStarted(id, { dispatch, getState, extra, requestId, queryFulfilled, getCacheEntry, updateCachedData }) { },
            // // handle subscriptions etc
            // onCacheEntryAdded(id, { dispatch, getState, extra, requestId, cacheEntryRemoved, cacheDataLoaded, getCacheEntry, updateCachedData }) { },
        }),
    })
})

// Action creators are generated for each case reducer function
export const { useGetUserInfoQuery } = agroverifiedApi
