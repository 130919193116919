import { FaDatabase } from 'react-icons/fa'
import { IoCartOutline, IoWarningOutline } from 'react-icons/io5'
import { MdVerifiedUser } from 'react-icons/md'
import { HiOutlineChatAlt2 } from 'react-icons/hi'
import { routes } from '../../../global/utils/routeNames'

export const services = [{
    icon: <FaDatabase />,
    name: "data place",
    description: "Looking to trade? Visit our dataplace.",
    color: "#B5CE1B",
    slug: routes.dataplace,
    disabled: true
}, {
    icon: <IoCartOutline />,
    name: "market place",
    description: "Search for a verified agropreneur.",
    color: "#59750E",
    slug: routes.market,
    disabled: true
}, {
    icon: <MdVerifiedUser />,
    name: "verification",
    description: "We verify and onboard authenticated farmers with confidence.",
    color: "#B5CE1B",
    slug: routes.verify_account,
    disabled: false
}, {
    icon: <HiOutlineChatAlt2 />,
    name: "community",
    description: "We connect verified farmers, aggregators, and processors to a larger community.",
    color: "#59750E",
    slug: routes.community,
    disabled: true
}, {
    icon: <IoWarningOutline />,
    name: "caution list",
    description: "We provide a list of farmers to be cautious of.",
    color: "#B5CE1B",
    slug: routes.caution_list,
    disabled: true
}]