import React, { Component } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
// import CurrentLocation from './CurrentLocation'

const mapStyles = {
    width: '100%',
    height: '100%',
};

export class MapContainer extends Component {
    state = {
        showingInfoWindow: false,  // Hides or shows the InfoWindow
        activeMarker: {},          // Shows the active marker upon click
        selectedPlace: {}          // Shows the InfoWindow to the selected place upon a marker
    };
    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };
    render() {
        return (
            // <CurrentLocation
            //     centerAroundCurrentLocation
            //     google={this.props.google}
            // >
            <>
                {
                    this.props.markers.length !== 0 &&
                    <Map
                        google={this.props.google}
                        zoom={this.props.single ? 12 : 6}
                        style={mapStyles}
                        initialCenter={{
                            lat: (this.props.single && this.props.markers[0].latitude) ? this.props.markers[0].latitude : 9.223472534578676,
                            lng: (this.props.single && this.props.markers[0].longitude) ? this.props.markers[0].longitude : 7.6059561436001495
                        }}
                    >
                        {this.props.markers.map((marker, index) => (
                            <Marker
                                key={index}
                                // onClick={this.onMarkerClick}
                                name={marker.type_of_crop_livestock}
                                position={{ lat: marker.latitude, lng: marker.longitude }}
                            >
                                {/* <InfoWindow
                                    marker={this.state.activeMarker}
                                    visible={this.state.showingInfoWindow}
                                    onClose={this.onClose}
                                >
                                    <div>
                                        <h4>{marker.type_of_crop_livestock}</h4>
                                    </div>
                                </InfoWindow> */}
                            </Marker>))}
                    </Map>}
            </>
            // </CurrentLocation>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API
})(MapContainer);