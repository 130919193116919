import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const contactGsap = (container, text, map) => {
    const tl = gsap.timeline()
    const scrollTrigger = {
        trigger: [container.current],
        toggleActions: "play none none none",
        start: 'center bottom',
        // end: () => `+=${container.current.offsetHeight}`,
        // scrub: true
    }
    gsap.from([text.current], {
        scrollTrigger,
        xPercent: -100,
        opacity: 0,
        ease: 'slow.out',
        duration: 0.5,
    })
    gsap.from([map.current], {
        scrollTrigger,
        opacity: 0,
        xPercent: 100,
        ease: 'sine.out',
        duration: 0.5,
        delay: 0.5,
    })
}