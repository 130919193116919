import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles/filter.module.scss';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'
import { setMembersFilter, setMembersReload } from '../../state/slices/members.slice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
}));

export default function MenuListComposition({ filter }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    //change filter
    const dispatch = useDispatch()
    const changeFilter = (e, newFilter) => {
        dispatch(setMembersFilter(newFilter))
        dispatch(setMembersReload(true))
        handleClose(e)
    }

    return (
        <div className={classes.root}>
            <div className={styles.container}>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    Filter {!open ? <IoChevronDown className={styles.icon} /> : <IoChevronUp className={styles.icon} />}
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper className={styles.paper}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={(e) => changeFilter(e, 'active')} className={filter === "active" ? styles.active : ''}>
                                            Active
                                        </MenuItem>
                                        <MenuItem onClick={(e) => changeFilter(e, 'inactive')} className={filter === "inactive" ? styles.active : ''}>
                                            Inactive
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}