import { Carousel } from "antd";
import { useLayoutEffect, useRef } from "react";
import { textEffect } from "../../global/animations/text";
import styles from "./styles/hero.module.scss"
import { heroImages } from "./utils/hero-images";
import { heroGsap } from './animations'

const Hero = () => {
    const heroRef = useRef(null)
    const tinyTextRef = useRef(null)
    const largeTextRef = useRef(null)
    const bannerRef = useRef(null)
    const textRef = useRef(null)
    const texts = ['Connect with', 'Verify', 'Trade with']

    useLayoutEffect(() => {
        heroGsap(heroRef, tinyTextRef, largeTextRef, bannerRef)
    }, [])

    useLayoutEffect(() => {
        textEffect(textRef, texts)
    })
    return (
        <div ref={heroRef} className={styles.container}>
            <Carousel autoplay pauseOnHover={false} autoplaySpeed={5000} easing="easeIn" effect="fade">
                {heroImages.map((heroImage, index) => (
                    <div key={index} className={styles.heromain}>
                        <picture>
                            <img src={heroImage} loading="eager" alt="home-hero" />
                        </picture>
                        <section>
                            <p ref={tinyTextRef}>verifying farmers</p>
                            <h2 ref={largeTextRef}>AGROVERIFIED</h2>
                        </section>
                    </div>
                ))}
            </Carousel>
            <div className={styles.herofooter} ref={bannerRef}>
                <h2><span ref={textRef}>Connect</span> players in the Agricultural sector.</h2>
                <p>A digital integrity platform that helps to facilitate trade, connect players and minimize fraud in the agriculture sector across africa.</p>
            </div>
        </div>
    )
};
export default Hero;