import http from '../../../http-common'

export const loginApi = async (values, setLoginError) => {
    const request = {
        method: 'POST',
        url: "/auth/login",
        data: {}
    };
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.userName)) {
        request.data = { email: values.userName, password: values.password }
    }
    else {
        request.data = { phone_number: values.userName, password: values.password }
    }
    try {
        let response = await http({
            ...request
        }).catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log(err.response)
                setLoginError(true)
            } else if (err.request) {
                // client never received a response, or request never left
                console.log(err.request)
            } else {
                // anything else
                console.log(err)
            }
        })
        if (response && response.status === 200) {
            setLoginError(false)
            const data = response.data
            return response = {
                value: data,
                status: 200
            }
        }
    } catch (e) {
        console.log(e)
    }
}