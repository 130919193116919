import Avatar from '@material-ui/core/Avatar';
import styles from './styles/farmer.module.scss'

const Farmer = ({ marker, setShowModal, setSelectedID }) => {
    const handleClick = () => {
        setShowModal(true)
        setSelectedID(marker.user_id)
    }
    return (
        <div onClick={handleClick} className={styles.container}>
            <p className={`${styles.id} ${styles.desktop}`}><span className={styles.heading}>Agro-ID: </span>{marker.agro_id || 'n/a'}</p>
            <p className={styles.farmer}>
                {marker.photo_url ?
                    <Avatar className={styles.avatar} alt={marker.first_name} src={marker.photo_url} /> :
                    <Avatar className={styles.avatar}>{marker.first_name.charAt(0)}</Avatar>
                }
                <span className={styles.farmerInfo}>
                    <span className={styles.name}>{marker.first_name + ' ' + marker.last_name}</span>
                    {marker.email && <span className={styles.email}>{marker.email}</span>}
                </span>
            </p>
            <p className={`${styles.id} ${styles.mobile}`}><span className={styles.heading}>Agro-ID: </span>{marker.agro_id || 'n/a'}</p>
            <p className={styles.location}><span className={styles.heading}>Location: </span>{marker.details || marker.location}</p>
            <p className={styles.capacity}><span className={styles.heading}>Capacity: </span>{marker.capacity}</p>
            {/* <p className={styles.size}><span className={styles.heading}>Size: </span>{marker.size}</p> */}
            <p className={styles.farming_type} title={marker.type_of_crop_livestock}><span className={styles.heading}>Type Of Farming: </span>{marker.type_of_crop_livestock}</p>
        </div>
    )
}

export default Farmer
