import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'
import { setLoader } from '../../state/slices/loader.slice'
import { setMembersReload } from '../../state/slices/members.slice'
import { toggleMemberApi } from './apis/togglememberstatus.api'
import styles from './styles/confirm.module.scss'

const Confirm = ({ employeeId, setEmployeeId, setShowModal, modalAction, setReload }) => {
    const dispatch = useDispatch()
    const deleteEmployee = async () => {
        dispatch(setLoader(true))
        await toggleMemberApi(employeeId, modalAction).then(response => {
            setEmployeeId('')
            setShowModal(false)
            dispatch(setMembersReload(true))
            dispatch(setLoader(false))
            return response
        })
    }
    return createPortal(
        <div className={styles.container}>
            <div className={styles.box}>
                <h4>Confirm Action</h4>
                <p>Are you sure you want to {modalAction} this employee?</p>
                <div className={styles.buttons}>
                    <button onClick={() => setShowModal(false)} className={styles.cancel}>Cancel</button>
                    <button onClick={deleteEmployee} className={styles.act}>{modalAction}</button>
                </div>
            </div>
        </div>
        , document.getElementById('portal'))
}

export default Confirm
