import { useState } from 'react';
import _ from 'lodash'
import { HiChevronUp, HiChevronDown } from 'react-icons/hi'
import styles from './styles/usertype.module.scss'

function UserType({ userType, setUserType }) {
    const [showBox, setShowBox] = useState(false)
    const userTypes = ['End User', 'Farmer', 'Institution']
    const handleBox = () => {
        setShowBox(prev => !prev)
    }
    const closeOnClick = (type) => {
        setUserType(_.snakeCase(type))
        setShowBox(false)
    }
    return (
        <div className={styles.container}>
            <div className={styles.box}>
                <div onClick={handleBox} className={styles.select}>
                    <h3>{userType ? userType.split('_').join(' ') : 'Select Account Type'}</h3>
                    <span>{showBox ? <HiChevronUp /> : <HiChevronDown />}</span>
                </div>
                {showBox &&
                    <div className={styles.list}>
                        {userTypes && userTypes.map((userType, index) => <h3 onClick={() => closeOnClick(userType)} key={index}>{userType}</h3>)}
                    </div>
                }
            </div>
            <button onClick={() => setShowBox(true)} className={styles.change}>Click to change</button>
        </div>
    )
}

export default UserType
