import { useState } from 'react'
import { FiArrowRight } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { setLoader } from '../../state/slices/loader.slice'
import { verifyTokenApi } from './apis/verifytoken.api'
import VerificationModal from './VerificationModal'
import styles from './styles/poolsearch.module.scss'

const PoolSearch = () => {
    const [code, setCode] = useState('')
    const [farmer, setFarmer] = useState({})
    const [showModal, setShowModal] = useState(false)
    const dispatch = useDispatch()
    const submit = async () => {
        dispatch(setLoader(true))
        const response = await verifyTokenApi(code).then(response => {
            dispatch(setLoader(false))
            return response
        })
        if (response && response.status === 200) {
            setCode('')
            setFarmer(response.value || {})
            response.value.pool_id && setShowModal(true)
        }
    }
    const closeModal = () => {
        setShowModal(false)
    }
    return (
        <div className={styles.container} onKeyDown={({ key }) => key === 'Enter' && code.length !== 0 && submit()}>
            <input
                type="text"
                name="pool token"
                id="pool token"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="enter pool token"
            />
            <button onClick={() => code.length !== 0 && submit()} className={styles.icon}>
                <FiArrowRight />
            </button>
            {showModal && <VerificationModal closeModal={closeModal} farmer={farmer} />}
        </div>
    )
}

export default PoolSearch
