import ReactDOM from "react-dom"
import { useDispatch } from 'react-redux'
import { setShowModal, setStatus } from '../../state/slices/modal.slice'
import ModalContent from './ModalContent'
import styles from './styles/modal.module.scss'
import { useRef } from "react"

const Modal = () => {
    const modalRef = useRef()
    const dispatch = useDispatch()
    const closeModal = (e) => {
        if (modalRef.current === e.target) {
            dispatch(setStatus(""))
            dispatch(setShowModal(false))
        }
    }
    return ReactDOM.createPortal(
        <div ref={modalRef} onClick={closeModal} className={styles.container}>
            <ModalContent />
        </div>,
        document.getElementById('portal')
    )
}

export default Modal
