import Search from './Search'
import { useSelector } from 'react-redux'

const Main = () => {
    const userInfo = useSelector(state => state.userInfo.value)

    return (
        <>
            {!userInfo.is_deleted && <Search userInfo={userInfo} />}
        </>
    )
}

export default Main
