import { useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import _ from 'lodash'
import Avatar from '@material-ui/core/Avatar';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import styles from './styles/verificationmodalcontent.module.scss'
import AvatarEnlarged from '../Shared/AvatarEnlarged'
dayjs.extend(relativeTime)

const VerificationModalContent = ({ closeModal, farmer }) => {
    const [avatarModal, setAvatarModal] = useState(false)

    const details = [
        {
            title: 'Duration on Platform',
            content: dayjs().from(dayjs(farmer.createdAt), true) || ''
        }, {
            title: 'Phone Number',
            content: farmer.phone_number || ''
        }, {
            title: 'Email',
            content: farmer.email || ''
        }, {
            title: 'Location',
            content: farmer.details || ''
        }, {
            title: 'Capacity',
            content: farmer.capacity || ''
        }, {
            title: 'Size',
            content: farmer.size || ''
        }, {
            title: 'Farming Type',
            content: farmer.type_of_crop_livestock || ''
        }
    ]


    return (
        <div className={styles.container}>
            <span onClick={closeModal} className={styles.close}><IoMdClose /></span>
            <div className={styles.farmerInfo}>
                <div className={styles.top}>
                    <div className={styles.poolButton}>
                        <h4 className={styles.poolName}><span>Pool Name: </span>{farmer.pool_name}</h4>
                    </div>
                    {farmer.photo_url ?
                        <Avatar onClick={() => setAvatarModal(true)} className={styles.avatar} alt={farmer.first_name} src={farmer.photo_url} /> :
                        <Avatar className={styles.avatar}>{farmer.first_name.charAt(0)}</Avatar>
                    }
                    <h3 className={styles.name}>{farmer.first_name + ' ' + farmer.last_name}</h3>
                    {farmer.user_type === "farmer" && <h4 className={styles.id}><span>Agro-ID: </span>{farmer.agro_id || 'n/a'}</h4>}
                </div>
                <section className={styles.text}>
                    {details && details.map((detail, index) => (
                        detail.content && <p key={index} className={styles[_.kebabCase(detail.title)]}><span className={styles.heading}>{detail.title}:</span>{detail.content}</p>
                    )
                    )}
                </section>
            </div>

            {farmer.photo_url && avatarModal && <AvatarEnlarged src={farmer.photo_url} alt={farmer.first_name} setShowModal={setAvatarModal} />}
        </div>
    )
}

export default VerificationModalContent
