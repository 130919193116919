import { useRef, useLayoutEffect } from 'react'
import { partners } from './utils/partners'
import { partnersGsap } from './animations'
import Headings from '../Shared/Headings'
import styles from './styles/partners.module.scss'

const Partners = () => {
    const partnersRef = useRef(null)
    const partnersHeadingRef = useRef(null)
    const partnerRef = useRef(null)
    useLayoutEffect(() => {
        partnersGsap(partnerRef, partnersHeadingRef, partnerRef)
    }, [])
    return (
        <div ref={partnersRef} className={styles.container}>
            <Headings headingRef={partnersHeadingRef} heading="our partners" />
            <div className={styles.images}>
                {partners && partners.map((partner, index) =>
                    <picture id="partner" ref={partnerRef} key={index}>
                        <img src={partner.img} alt="partners" />
                    </picture>)
                }
            </div>
        </div>
    )
}

export default Partners
