import Meta from '../components/Meta/Meta';
import Main from '../components/InstitutionSearch/Main';

const InstitutionSearch = () => {
    const meta = {
        title: "Search Database",
        keywords: "agriculture, farmers, search database, nigeria, market place, community, agroverified",
        description: "Agroverified is a leading integrity ecosystem for agri-business across the world, we provide verified information on farmer’s products, farmlands, farmer’s geo-location, and crop types for transparent business transactions on a secure digital platform."
    }
    return (
        <>
            <Meta {...meta} />
            <Main />
        </>
    )
}

export default InstitutionSearch
