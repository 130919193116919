import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);


export const servicesGsap = (container, heading, paragraph, service) => {
    const tl = gsap.timeline({ delay: 1 })
    const scrollTrigger = {
        trigger: [container.current],
        toggleActions: "restart none none reset",
        start: 'center bottom',
        // end: () => `+=${container.current.offsetHeight}`,
        // scrub: true
    }
    gsap.from([heading.current], {
        scrollTrigger,
        xPercent: -60,
        opacity: 0,
        duration: 0.5,
    })
    gsap.from([paragraph.current], {
        scrollTrigger,
        xPercent: 60,
        opacity: 0,
        duration: 0.5,
        delay: 0.5,
    })
    gsap.from('#service', {
        scrollTrigger,
        stagger: {
            amount: 1.5,
        },
        opacity: 0,
        yPercent: 40,
        ease: "power1.inOut",
        duration: 0.5,
        delay: 1,
    })
}