import { Formik, Form } from 'formik'
import { recordsValidationSchema } from '../utils/formvalidation'
import { recordsInitialValues } from '../utils/forminitialvalues'
import RecordsForm from './RecordsForm'
import { useDispatch } from 'react-redux'
import { setLoader } from '../../../state/slices/loader.slice'
import { recordsApi } from '../api/records.api'
import styles from '../styles/updateprofile.module.scss'

const Records = ({ userInfo }) => {
    const dispatch = useDispatch()
    return (
        <div className={styles.container}>
            {userInfo._id && <Formik onSubmit={async (values, { resetForm, setSubmitting }) => {
                dispatch(setLoader(true))
                const response = await recordsApi(values).then(response => {
                    dispatch(setLoader(false))
                    setSubmitting(false)
                    return response
                })
                if (response && response.status === 200) {
                    resetForm()
                    // setTab(0)
                }
            }}
                initialValues={recordsInitialValues}
                validationSchema={recordsValidationSchema}>

                {formik =>
                    <Form>
                        <RecordsForm formik={formik} />
                    </Form>
                }

            </Formik>}
        </div>
    )
}

export default Records
