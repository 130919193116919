import { DatePicker, Space } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styles from '../styles/datepickerinput.module.scss'

const DatePickerInput = ({ formik, field, defaultValue }) => {
    const [currentValue, setCurrentValue] = useState('')
    const onChange = (date, dateString) => {
        formik.setFieldValue(field, dateString)
    }

    useEffect(() => {
        if (defaultValue) {
            // onChange(moment(defaultValue), defaultValue)
            setCurrentValue(moment(defaultValue), defaultValue)
        }
    }, [defaultValue])
    return (
        <div className={styles.container}>
            <Space direction="vertical">
                <DatePicker value={currentValue} onChange={onChange} placeholder="YYYY-MM-DD" />
            </Space>
        </div>
    )
}

export default DatePickerInput
