import * as Yup from 'yup'

export const institutionVerificationValidationSchema = Yup.object().shape({
    institution_name: Yup.string().required('field is required'),
    business_category: Yup.string().required('field is required'),
    cbn_number: Yup.string().required('field is required'),
    institution_rc_number: Yup.string().required('field is required'),
    institution_email: Yup.string().email('email is not valid').required('field is required'),
    institution_phone_number: Yup.string()
        .matches('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,7}$',
            { message: "invalid phone number" })
        .required('phone number is required'),
    director_name: Yup.string().required('field is required'),
    name_of_primary_contact: Yup.string().required('field is required'),
    head_office_address: Yup.string().required('field is required')
})
