import Headings from '../Shared/Headings'
import styles from './styles/howitworks.module.scss'
import ReactPlayer from 'react-player/lazy'
import { useLayoutEffect, useRef } from 'react'
import { howItWorksGsap } from './animations'

const HowItWorks = () => {
    const howItWorksRef = useRef(null)
    const hiwHeadingRef = useRef(null)
    const hiwVideoRef = useRef(null)
    useLayoutEffect(() => {
        howItWorksGsap(howItWorksRef, hiwHeadingRef, hiwVideoRef)
    }, [])
    return (
        <div ref={howItWorksRef} className={styles.container}>
            <Headings headingRef={hiwHeadingRef} heading="how it works" />
            <div ref={hiwVideoRef} className={styles.video}>
                <ReactPlayer url='https://www.youtube.com/watch?v=2CRLJzHRaXY'
                    controls
                    width='100%'
                    height='100%' />
            </div>
        </div>
    )
}

export default HowItWorks
