import { createSlice } from '@reduxjs/toolkit'

export const institutionMembersSlice = createSlice({
    name: 'institution members',
    initialState: {
        value: [],
        reload: true,
        filter: 'active'
    },
    reducers: {
        setInstitutionMembers: (state, { payload }) => {
            state.value = payload
        },
        setMembersReload: (state, { payload }) => {
            state.reload = payload
        },
        setMembersFilter: (state, { payload }) => {
            state.filter = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setInstitutionMembers, setMembersReload, setMembersFilter } = institutionMembersSlice.actions

export default institutionMembersSlice.reducer