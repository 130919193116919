import Headings from '../Shared/Headings'
import Service from './Service'
import { services } from './utils/services'
import styles from './styles/services.module.scss'
import { useRef } from 'react'
import { useLayoutEffect } from 'react'
import { servicesGsap } from './animations'

const Services = () => {
    const servicesRef = useRef(null)
    const serviceRef = useRef(null)
    const servicesHeadingRef = useRef(null)
    const servicesParagraphRef = useRef(null)

    useLayoutEffect(() => {
        servicesGsap(servicesRef, servicesHeadingRef, servicesParagraphRef, serviceRef)
    }, [])

    return (
        <div ref={servicesRef} className={styles.container}>
            <Headings headingRef={servicesHeadingRef} heading="our services" />
            <p ref={servicesParagraphRef}>We offer an all in one platform to join, connect, share, and get verified information from the agriculture industry.</p>
            <div className={styles.services}>
                {services.map((service, index) => <Service serviceRef={serviceRef} key={index} service={service} />)}
            </div>
        </div>
    )
}

export default Services
