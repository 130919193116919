import { useState } from 'react'
import styles from './styles/confirmnumber.module.scss'

const ConfirmNumber = ({ setConfirmed, userInfo }) => {
    const [phone, setPhone] = useState(userInfo.phone_number || '')
    const [error, setError] = useState(false)
    const handleChange = (e) => {
        setPhone(e.target.value)
    }
    const verify = () => {
        if (userInfo.phone_number === phone) {
            setError(false)
            setConfirmed(true)
        } else {
            setError(true)
        }
    }

    return (
        <div className={styles.container}>
            <h3>Confirm Your Phone Number To Check Status</h3>
            {userInfo.phone_number && <div className={styles.inputContainer}>
                <input type="tel" value={phone} onChange={handleChange} />
                <button onClick={verify} type="submit">Confirm</button>
            </div>}
            {error && <div className={styles.error}>
                <p>Please enter the phone number registered with this account.</p>
            </div>}
        </div>
    )
}

export default ConfirmNumber
