export const termsofuse = [
    {
        id: 1,
        title: "Summary of service",
        content: "Agroverified is an agric-tech integrity ecosystem for agri-business across the world, we provide verified information on farmer’s products, farmlands, farmer’s geo-location, and crop types for transparent business transactions on a secure digital platform.<br/>Our agro-changing platform leverages technology to connect verified farmers, aggregators and producers access to a larger market, trading validation, carbon credit, and finance, a unique opportunity to maximize profit and the possibility to achieve global food security."
    }, {
        id: 2,
        title: "Definitions",
        content: "In these Terms of Use, we refer to those we verify their information as “Famers, Aggregators and Producers' ' and their products as “Farmlands, Crop Types, Animal Types, those who have opted in for verified information as “Users”. Other users of the Service and visitors to the Site are referred to collectively as “Users.” Information about happenings on the platform can be found and referred to as “Agripreneur Community”. An “ Agripreneur Marketplace” is a marketplace where users and  Agripreneur transact safely."
    }, {
        id: 3,
        title: "Acceptance of terms",
        content: "The Service is offered subject to acceptance of all of the terms and conditions contained in these Terms of Use and all other operating rules, policies, and procedures that may be published on the Site by the Company, which are incorporated by reference, including operating rules, policies, and procedures of third party services providers to the Site that are referenced herein. These Terms of Use apply to every user of the Service. In addition, some Services offered through the Site may be subject to additional terms and conditions adopted by the Company. Your use of those services is subject to those additional terms and conditions, which are incorporated into these Terms of Use by this reference.<br/>The Company reserves the right, at its sole discretion, to modify or replace these Terms of Use from time to time by posting the updated terms on the Site. It is your responsibility to check the Terms of Use periodically for changes. If you object to any such changes, your sole recourse will be to cease using the Site and the Service. Your continued use of the Service following the posting of any changes to the Terms of Use will indicate your acknowledgment of such changes and agreement to be bound by the terms and conditions of such changes.<br/>The Company reserves the right to change, suspend, or discontinue the Service (including, but not limited to, the availability of any feature, database, or Content) at any time for any reason. The Company may also impose limits on certain features and Services or restrict your access to parts of or the entire Site without notice or liability.<br/>By submitting such information, you grant us the right to provide the information to third parties for purposes of facilitating the completion of Purchases."
    }, {
        id: 4,
        title: "Accounts",
        content: "When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.<br/>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.<br /> You agree not to disclose your password to any third party.You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.<br /> You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene."
    }, {
        id: 5,
        title: "Intellectual Property",
        content: "The Service and its original content, features, and functionality are and will remain the exclusive property of Agrovalidate Limited. The Service is protected by copyright, trademark, and other laws of The Federal Republic of Nigeria. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Agrovalidate Limited.<br/>User shall not directly or indirectly: (i) decipher, decompile, disassemble, reverse engineer, or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Service, except to the extent applicable laws specifically prohibit such restriction; (ii) modify, translate, or otherwise create derivative works of any part of the Service; or (iii) copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive hereunder.<br/>If you choose to provide technical, business, or other feedback to the Company concerning the Site or the Services (collectively, “Feedback”), the Company will be free to use, disclose, reproduce, license, or otherwise distribute or exploit such Feedback in its sole discretion without any obligations or restrictions of any kind, including intellectual property rights or licensing obligations. You understand and agree that the incorporation by the Company of Feedback into any of its products or services does not grant you any proprietary rights therein."
    }, {
        id: 6,
        title: "Links to Other Websites",
        content: "Our Service may contain links to third-party websites or services that are not owned or controlled by Agroverified.<br/>Agroverified has no control over and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that Agroverified shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods, or services available on or through any such web sites or services.<br/>We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit."
    }, {
        id: 7,
        title: "Termination",
        content: "We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.<br/>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service."
    }, {
        id: 8,
        title: "Indemnification",
        content: "Shall defend, indemnify, and hold harmless Agroverified, its affiliates, and each of its, and its affiliates employees, contractors, directors, suppliers, and representatives from all liabilities, claims, and expenses, including reasonable attorneys’ fees, that arise from or relate to your use or misuse of, or access to, the Site, Service, Content or otherwise from your User Submissions, violation of the Agreement, or infringement by you, or any third party using your account, of any intellectual property or other rights of any person or entity. Agroverified reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will assist and cooperate with Agroverifiedin asserting any available defenses."
    }, {
        id: 9,
        title: "Limitation Of Liability",
        content: "In no event shall Agroverified, its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose."
    }, {
        id: 10,
        title: "Disclaimer",
        content: "Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.<br/>Agroverified,  its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure, or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components, or d) the results of using the Service will meet your requirements."
    }, {
        id: 11,
        title: "Governing Law",
        content: "These Terms shall be governed and construed by the laws of The Federal Republic of Nigeria, without regard to its conflict of law provisions.<br/>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service."
    }, {
        id: 12,
        title: "Changes To Terms",
        content: "Agroverified reserves the right, in its sole discretion, to change the Terms under which agroverified.com is offered. The most current version of the Terms will supersede all previous versions. Agroverified encourages you to periodically review the Terms to stay informed of our updates."
    }, {
        id: 13,
        title: "Contact Us",
        content: "If you have any questions about these Terms, please contact us at: <a href='mailto: info@agroverified.com'>info@agroverified.com</a>."
    }
]