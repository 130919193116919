import Contact from './Contact'
import Getintouch from './Getintouch'
import styles from './styles/main.module.scss'

const Main = () => {
    return (
        <div className={styles.container}>
            <Contact />
            <Getintouch />
        </div>
    )
}

export default Main
