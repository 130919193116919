import { Formik, Form, Field } from 'formik'
import { verification } from "./apis/verification.api"
import { useDispatch } from "react-redux"
import { setLoader } from "../../state/slices/loader.slice"
import { setShowModal, setStatus } from "../../state/slices/modal.slice"
import { verificationInitialValues } from "./utils/forminitialvalues"
import { verificationValidationSchema } from "./utils/formvalidation"
import styles from './styles/verification.module.scss'

const Verification = () => {
    const dispatch = useDispatch()

    const focusNextInput = e => {
        const { maxLength, value, name } = e.target;
        const [, fieldIndex] = name.split("_");
        if (value.length >= maxLength) {
            if (parseInt(fieldIndex, 10) < 6) {
                const nextSibling = document.querySelector(
                    `input[name=code_${parseInt(fieldIndex, 10) + 1}]`
                );
                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        }
    }
    return (
        <div>
            <Formik
                initialValues={verificationInitialValues}
                validationSchema={verificationValidationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    dispatch(setLoader(true))
                    const code = Object.values(values).reduce((acc, current) => acc + current)
                    const response = await verification(code).then(response => {
                        dispatch(setLoader(false))
                        setSubmitting(false)
                        return response
                    })
                    if (response.status === 200) {
                        resetForm()
                        dispatch(setShowModal(false));
                        dispatch(setStatus(''))
                    }
                }}>
                {formik =>
                    <Form className={styles.container}>
                        <h3>OTP Verification</h3>
                        <p className={styles.kindly}>We have sent you a <span>One Time Password</span> on the phone number you signed up with.</p>
                        <div className={styles.inputs}>
                            {[1, 2, 3, 4, 5, 6].map((item, index) => <Field
                                key={index}
                                autoFocus={item === 1}
                                autoComplete="off"
                                maxLength="1"
                                className={formik.touched[`code_${item}`] && formik.errors[`code_${item}`] ? styles.touched : ''}
                                name={`code_${item}`}
                                type='text'
                                onChange={(e) => {
                                    formik.handleChange(e)
                                    focusNextInput(e)
                                }}
                                onPaste={(e) => {
                                    let paste = (e.clipboardData || window.clipboardData).getData('text');
                                    [0, 1, 2, 3, 4, 5].forEach(input => {
                                        formik.setFieldValue(`code_${input + 1}`, paste[input])
                                    })
                                }}
                            />)}
                        </div>
                        <button type="submit" disabled={formik.isSubmitting} className={styles.OTPButton}>Complete Registration</button>
                    </Form>
                }
            </Formik>
        </div>
    )
}

export default Verification
