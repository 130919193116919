import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);


export const statementsGsap = (container, statement) => {
    const tl = gsap.timeline()
    const scrollTrigger = {
        trigger: [container.current],
        toggleActions: "restart none none reset",
        start: 'center bottom',
        // end: () => `+=${container.current.offsetHeight}`,
        // scrub: true
    }
    gsap.from('#statement', {
        scrollTrigger,
        stagger: {
            amount: 1,
        },
        opacity: 0,
        yPercent: 10,
        scale: 0.2,
        ease: "strong.out",
        duration: 0.5
    })
}