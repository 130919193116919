import { useEffect, useState, useRef } from 'react'
import Headings from '../Shared/Headings'
import { changeFarmer } from './utils/agropreneurs'
import { agropreneursApi } from './api/agropreneurs.api'
import Agropreneur from './Agropreneur'
import { agropreneursGsap } from './animations'
import styles from './styles/agropreneurs.module.scss'

const Agropreneurs = () => {
    const [xamplePreneurs, setXamplePreneurs] = useState([])
    const [agropreneurs, setAgropreneurs] = useState([])
    const [fade, setFade] = useState(false)
    const [active, setActive] = useState(-1)
    const [hoveredIndex, setHoveredIndex] = useState(-1)
    useEffect(() => {
        const getAgropreneurs = async () => {
            const agropreneurs = await agropreneursApi().then((response => response))
            setAgropreneurs(agropreneurs || [])
        }
        getAgropreneurs()
    }, [])
    useEffect(() => {
        const setRandomPreneurs = () => {
            const randomNumbers = Array.from({ length: agropreneurs.length - 1 }, () => Math.floor(Math.random() * agropreneurs.length))
            const randomSet = [...new Set(randomNumbers)]
            const uniqueNumbers = ['_', '_', '_', '_'].map((item, index) => randomSet[index])
            const randomPreneurs = uniqueNumbers.map(i => agropreneurs[i])
            setXamplePreneurs(randomPreneurs)
        }
        agropreneurs.length !== 0 && setRandomPreneurs()
    }, [agropreneurs])
    useEffect(() => {
        const changeFarmerTimeout = agropreneurs.length !== 0 && setTimeout(() => {
            setXamplePreneurs(prev => {
                const preneurs = [...prev]
                let [newPreneur, position] = changeFarmer(agropreneurs)
                let exist = preneurs.filter((preneur) => preneur.phone_number === newPreneur.phone_number)
                if (exist.length === 0 && position !== hoveredIndex) {
                    setActive(position)
                    setFade(true)
                    preneurs[position] = newPreneur
                    setTimeout(() => {
                        setFade(false)
                        setActive(-1)
                    })
                } else {
                    setFade(false)
                    setActive(-1)
                    // if (position === hoveredIndex) {
                    //     console.log('cannot change because hovered')
                    // } else {
                    //     console.log('can\'t change')
                    // }
                }
                return preneurs;
            })
        }, 4000)
        return () => {
            clearTimeout(changeFarmerTimeout)
        }
    })
    const agropreneursRef = useRef(null)
    const agropreneursHeadingRef = useRef(null)
    const agropreneursContainerRef = useRef(null)
    useEffect(() => {
        agropreneursGsap(agropreneursRef, agropreneursHeadingRef, agropreneursContainerRef)
    }, [])
    return (
        <div ref={agropreneursRef} className={styles.container}>
            <Headings headingRef={agropreneursHeadingRef} heading="agropreneurs" />
            <div ref={agropreneursContainerRef} className={styles.agropreneurs}>
                {
                    xamplePreneurs.length !== 0 &&
                    xamplePreneurs.map((agropreneur, index) =>
                        <Agropreneur key={index} fade={fade}
                            active={active} index={index}
                            agropreneur={agropreneur} setHoveredIndex={setHoveredIndex} />
                    )
                }
            </div>
        </div>
    )
}

export default Agropreneurs
