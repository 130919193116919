import http from '../../../http-common'
import swal from "sweetalert";
import Cookies from "js-cookie";
import _ from 'lodash';

export const searchFarmlandApi = async (term) => {
    const currentUser = Cookies.get('auth') || "{}"
    const token = JSON.parse(currentUser).data?.token.token
    const request = {
        method: 'POST',
        url: "/search",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            authorization: 'Bearer ' + token
        },
        data: JSON.stringify({ search: term })
    };
    try {
        let response = await http({
            ...request
        }).catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log(err.response)
                swal({
                    icon: 'error',
                    text: err.response.data.error || err.response.data.message,
                    timer: 4000,
                    button: false,
                });
            } else if (err.request) {
                // client never received a response, or request never left
                console.log(err.request)
            } else {
                // anything else
                console.log(err)
            }
        })

        if (response && response.status === 200) {
            const data = response.data
            const changedData = data.data.map(farmland => {
                farmland.latitude = Number(farmland.latitude.slice(0, -1))
                farmland.longitude = Number(farmland.longitude.slice(0, -1))
                const { _id, createdAt, ...farmlandDetails } = farmland
                return { ...farmlandDetails }
            })
            const withFarmerDetails = await Promise.all(
                changedData.map((land) => {
                    const getFarmerDetails = async () => {
                        const response = await farmerDetailsApi(land.user_id).then(response => response)
                        if (response && response.status === 200) {
                            const { _id, ...farmer } = response.value
                            return {
                                ...land,
                                // farmer: {
                                ...farmer
                                // }
                            }
                        } else {
                            return land
                        }
                    }
                    return getFarmerDetails()
                })
            ).then(response => response)
            return response = {
                value: withFarmerDetails,
                status: 200
            }
        }
    } catch (e) {
        console.log(e)
    }
}

export const farmerDetailsApi = async (farmerId) => {
    const currentUser = Cookies.get('auth') || "{}"
    const token = JSON.parse(currentUser).data?.token.token
    const request = {
        method: 'GET',
        url: "/user/single/" + farmerId,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            authorization: 'Bearer ' + token
        }
    };
    try {
        let response = await http({
            ...request
        }).catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log(err.response)
                swal({
                    icon: 'error',
                    text: err.response.data.error || err.response.data.message,
                    timer: 4000,
                    button: false,
                });
            } else if (err.request) {
                // client never received a response, or request never left
                console.log(err.request)
            } else {
                // anything else
                console.log(err)
            }
        })

        if (response && response.status === 200) {
            const farmer = response.data.data
            farmer.first_name = _.capitalize(farmer.first_name)
            farmer.last_name = farmer.last_name ? _.capitalize(farmer.last_name) : ''
            return response = {
                value: farmer,
                status: 200
            }
        }
    } catch (e) {
        console.log(e)
    }
}