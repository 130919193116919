import { useState } from 'react'
import { Formik, Form } from 'formik'
import { farmlandValidationSchema } from '../utils/formvalidation'
import { farmlandInitialValues } from '../utils/forminitialvalues'
import FarmlandForm from './FarmlandForm'
import styles from '../styles/updateprofile.module.scss'
import { farmlandApi } from '../api/farmland.api'
import { setLoader } from '../../../state/slices/loader.slice'
import { useDispatch } from 'react-redux'

const Farmland = ({ userInfo, setTab }) => {
    const dispatch = useDispatch()
    const [urls, setUrls] = useState([])
    const [farmlandImage, setFarmlandImage] = useState([])

    return (
        <div className={styles.container}>
            {userInfo._id && <Formik onSubmit={async (values, { resetForm, setSubmitting }) => {
                dispatch(setLoader(true))
                const response = await farmlandApi(values, farmlandImage, urls).then(response => {
                    dispatch(setLoader(false))
                    setSubmitting(false)
                    return response
                })
                if (response && response.status === 200) {
                    resetForm()
                    // setTab(0)
                }
            }}
                initialValues={farmlandInitialValues}
                validationSchema={farmlandValidationSchema}>

                {formik =>
                    <Form id="farmlandForm" encType="multipart/form-data">
                        <FarmlandForm setUrls={setUrls} setFarmlandImage={setFarmlandImage} formik={formik} />
                    </Form>
                }

            </Formik>}
        </div>
    )
}

export default Farmland
