import { Link } from 'react-router-dom'
import { IoMdFolderOpen } from 'react-icons/io'
import styles from './styles/pool.module.scss'
import { routes } from '../../global/utils/routeNames'
import _ from 'lodash'

const Pool = ({ userInfo, button, setShowModal, setModalAction, pool }) => {
    const handleModal = () => {
        setShowModal(true)
        setModalAction(button)
    }
    return (
        <Link
            to={routes.institution_pools_members(_.kebabCase(pool.pool_name)) + `?pool=${pool._id}`} className={styles.container}>
            <h3>{pool.pool_name}</h3>
            <p>{pool.members?.length || 0} {(pool.members?.length === 0 || pool.members?.length > 1) ? 'Members' : 'Member'}</p>
            <span className={styles.icon}><IoMdFolderOpen /></span>
        </Link>
    )
}

export default Pool
