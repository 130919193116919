import ModalContent from './ModalContent'
import { createPortal } from 'react-dom'
import styles from './styles/modal.module.scss'
import { useRef } from 'react'

const Modal = ({ modalDirector, setShowModal, closeModal, style }) => {
    const modalRef = useRef()
    const closeModalTarget = (e) => {
        if (modalRef.current === e.target) {
            closeModal()
        }
    }
    return createPortal(
        <div ref={modalRef} style={style} onClick={closeModalTarget} className={styles.container}>
            <ModalContent modalDirector={modalDirector} closeModal={closeModal} setShowModal={setShowModal} />
        </div>
        , document.getElementById('portal'))
}

export default Modal
