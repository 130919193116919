import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom';
import LoginForm from './LoginForm'
import Cookies from "js-cookie"
import { setShowModal, setStatus } from '../../state/slices/modal.slice'
import styles from './styles/login.module.scss'
import { useEffect } from 'react';
import { routes } from '../../global/utils/routeNames';

const Login = ({ setAuthState }) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const history = useHistory();
    const auth = JSON.parse(Cookies.get('auth') || '{}')
    const institutionId = auth.data?.institution_id
    const statePath = location.state?.from?.pathname || ""

    useEffect(() => {
        if (auth.status === 'success') {
            const { from } = ((statePath.includes('/institution/') && institutionId) || !statePath.includes('/institution/')) ? location.state || { from: { pathname: routes.home } } : { from: { pathname: routes.profile } };
            history.replace(from)
        }
    }, [auth.status, history, location.state, statePath, institutionId])

    // login/signup state
    const openModal = (status) => {
        dispatch(setShowModal(true));
        dispatch(setStatus(status));
    }

    return (
        <div className={styles.container}>
            <h3>Sign in</h3>
            <p>Stay connected with verified farmers.</p>
            <LoginForm setAuthState={setAuthState} />
            <div className={styles.bottom}>
                <p>New to Agroverified?</p>
                <button onClick={() => openModal("signup")} className={styles.signup}>Create An Account</button>
            </div>
        </div>
    )
}

export default Login
