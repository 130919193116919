import { useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import Avatar from '@material-ui/core/Avatar';
import _ from 'lodash'
import Map from './Map'
import AddToPool from './AddToPool'
import AvatarEnlarged from './AvatarEnlarged'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import styles from './styles/farmermodalcontent.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '../../state/slices/loader.slice';
import { removeFromPoolApi } from './apis/removefrompool.api';
import { setPoolsReload } from '../../state/slices/pools.slice';
dayjs.extend(relativeTime)

const FarmerModalContent = ({ closeModal, modalFarmer, remove, poolID }) => {
    const [avatarModal, setAvatarModal] = useState(false)
    const userInfo = useSelector(state => state.userInfo.value)
    const marker = [
        {
            latitude: modalFarmer.latitude,
            longitude: modalFarmer.longitude,
            type_of_crop_livestock: modalFarmer.type_of_crop_livestock
        }
    ]
    const details = [
        {
            title: 'Duration on Platform',
            content: dayjs().from(dayjs(modalFarmer.createdAt), true) || ''
        }, {
            title: 'Phone Number',
            content: modalFarmer.phone_number || ''
        }, {
            title: 'Email',
            content: modalFarmer.email || ''
        }, {
            title: 'Location',
            content: modalFarmer.details || modalFarmer.location || ''
        }, {
            title: 'Capacity',
            content: modalFarmer.capacity || ''
        }, {
            title: 'Size',
            content: modalFarmer.size || ''
        }, {
            title: 'Farming Type',
            content: modalFarmer.type_of_crop_livestock || ''
        }
    ]

    // remove from pool
    const dispatch = useDispatch()
    const removeFromPool = async (agroID) => {
        dispatch(setLoader(true))
        const response = await removeFromPoolApi(poolID, agroID).then(response => {
            dispatch(setLoader(false))
            return response
        })
        if (response && response.status === 200) {
            dispatch(setPoolsReload(true))
            closeModal()
        }
    }

    return (
        <div className={styles.container}>
            <span onClick={closeModal} className={styles.close}><IoMdClose /></span>
            <div className={styles.farmerInfo}>
                <div className={styles.top}>
                    <div className={styles.poolButton}>
                        {(remove && userInfo.is_admin) && <button onClick={() => removeFromPool(modalFarmer.agro_id)} className={styles.remove}>Remove</button>}
                        {userInfo.is_admin && <AddToPool farmerDetails={modalFarmer} closeModal={closeModal} />}
                    </div>
                    {modalFarmer.photo_url ?
                        <Avatar onClick={() => setAvatarModal(true)} className={styles.avatar} alt={modalFarmer.first_name} src={modalFarmer.photo_url} /> :
                        <Avatar className={styles.avatar}>{modalFarmer.first_name.charAt(0)}</Avatar>
                    }
                    <h3 className={styles.name}>{modalFarmer.first_name + ' ' + modalFarmer.last_name}</h3>
                    {modalFarmer.user_type === "farmer" && <h4 className={styles.id}><span>Agro-ID: </span>{modalFarmer.agro_id || 'n/a'}</h4>}
                </div>
                <section className={styles.text}>
                    {details && details.map((detail, index) => (
                        detail.content && <p key={index} className={styles[_.kebabCase(detail.title)]}><span className={styles.heading}>{detail.title}:</span>{detail.content}</p>
                    )
                    )}
                </section>
            </div>
            <div className={styles.mapContainer}>
                <Map markers={marker} single />
            </div>
            {modalFarmer.photo_url && avatarModal && <AvatarEnlarged src={modalFarmer.photo_url} alt={modalFarmer.first_name} setShowModal={setAvatarModal} />}
        </div>
    )
}

export default FarmerModalContent
