export const institutionVerificationInitialValues = {
    institution_name: '',
    business_category: '',
    cbn_number: '',
    institution_rc_number: '',
    institution_email: '',
    institution_phone_number: '',
    director_name: '',
    name_of_primary_contact: '',
    head_office_address: '',
    cac_certificate_url: []
}