import React from "react";
import Hero from "./Hero";
import Services from "./Services";
import HowItWorks from "./HowItWorks";
import Agropreneurs from "./Agropreneurs";
import Connect from "./Connect";
import Stories from "./Stories";
// import Testimonials from "./Testimonials";
import Partners from './Partners';

const Main = () => {
    return (
        <>
            <Hero />
            <Services />
            <HowItWorks />
            <Agropreneurs />
            <Connect />
            <Stories />
            {/* <Testimonials /> */}
            <Partners />
        </>
    )
}

export default Main
