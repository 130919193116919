import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";

gsap.registerPlugin(TextPlugin);

const tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });

export const textEffect = (element, texts) => {
    texts.forEach(text => {
        tl.to([element.current], {
            duration: 4,
            text: {
                value: text,
                // newClass: "class2",
                delimiter: "",
                // padSpace: true,
                type: "diff",
                speed: 1
            },
            // ease: "power2"
        });
    })
}