const getRandomNumber = () => {
    const randomNumber = Math.floor(Math.random() * agropreneurs.length)
    return randomNumber
}
export const changeFarmer = (agropreneurs) => {
    const randomNumber = getRandomNumber()
    const position = Math.floor(Math.random() * 4)
    const newPreneur = agropreneurs[randomNumber]
    return [newPreneur, position]
}

export const agropreneurs = [{
    id: "1",
    name: "Gustavo Av",
    type_of_farmer: "Crops Farmer",
    photo_url: "https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cGVyc29ufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
}, {
    id: "2",
    name: "Abby Rain",
    type_of_farmer: "Livestock Farmer",
    photo_url: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
}, {
    id: "3",
    name: "Joseph Myrr",
    type_of_farmer: "Livestock Farmer",
    photo_url: "https://images.unsplash.com/photo-1547425260-76bcadfb4f2c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cGVyc29ufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
}, {
    id: "4",
    name: "Joe Aina",
    type_of_farmer: "Crops Farmer",
    photo_url: "https://images.unsplash.com/photo-1552058544-f2b08422138a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
}, {
    id: "5",
    name: "Mary Chuka",
    type_of_farmer: "Livestock Farmer",
    photo_url: "https://images.unsplash.com/photo-1554384645-13eab165c24b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzZ8fHBlcnNvbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
}, {
    id: "6",
    name: "Joe Kosoko",
    type_of_farmer: "Crops and Livestock Farmer",
    photo_url: "https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzR8fHBlcnNvbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
}, {
    id: "7",
    name: "Nengi Okoh",
    type_of_farmer: "Crops Farmer",
    photo_url: "https://images.unsplash.com/photo-1597586124394-fbd6ef244026?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzN8fHBlcnNvbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
}, {
    id: "8",
    name: "Shogo Shasha",
    type_of_farmer: "Livestock Farmer",
    photo_url: "https://images.unsplash.com/photo-1544723795-3fb6469f5b39?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzh8fHBlcnNvbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
}, {
    id: "9",
    name: "John Abba",
    type_of_farmer: "Livestock Farmer",
    photo_url: "https://images.unsplash.com/photo-1613152748447-e126a98fbdaa?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NjR8fHBlcnNvbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
}, {
    id: "10",
    name: "Wasola Akin",
    type_of_farmer: "Crops Farmer",
    photo_url: "https://images.unsplash.com/photo-1574784618880-a1036d310e7c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nzd8fHBlcnNvbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
}, {
    id: "11",
    name: "Ajani Money",
    type_of_farmer: "Crops and Livestock Farmer",
    photo_url: "https://images.unsplash.com/photo-1598411072028-c4642d98352c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NzB8fHBlcnNvbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
}, {
    id: "12",
    name: "Killian Please",
    type_of_farmer: "Crops and Livestock Farmer",
    photo_url: "https://images.unsplash.com/photo-1555092394-6ed41ac4afb8?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTd8fHBlcnNvbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
}, {
    id: "13",
    name: "Korede Fgs",
    type_of_farmer: "Livestock Farmer",
    photo_url: "https://images.unsplash.com/photo-1596813362035-3edcff0c2487?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NzZ8fHBlcnNvbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
}, {
    id: "14",
    name: "Pelumi Ade",
    type_of_farmer: "Crops and Livestock Farmer",
    photo_url: "https://images.unsplash.com/photo-1576582842668-ca5c297a5425?ixid=MnwxMjA3fDB8MHxzZWFyY2h8ODZ8fHBlcnNvbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
}, {
    id: "15",
    name: "Es Pretty",
    type_of_farmer: "Crops Farmer",
    photo_url: "https://images.unsplash.com/photo-1555524554-0fdb51cd5020?ixid=MnwxMjA3fDB8MHxzZWFyY2h8ODJ8fHBlcnNvbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
}]